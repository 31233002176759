import React from 'react';
import ReactDOM from 'react-dom/client';
import App from "./App";
import { library } from '@fortawesome/fontawesome-svg-core'
import {fas, faXmark} from '@fortawesome/free-solid-svg-icons'
import { faVk, faInstagram, faFacebookSquare, faTelegram } from '@fortawesome/free-brands-svg-icons'

library.add(fas, faVk, faInstagram, faFacebookSquare, faTelegram, faXmark)

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<App />);
