import React from 'react';
import {Link} from "react-router-dom";
import ButtonArrow from "../../../components/buttons/button-arrow";
import Headline1 from "../../../components/typography/headline1";
import Regular from "../../../components/typography/regular";
import escort from "../../../images/servicePages/escort.png";
import Button from "../../../components/buttons/button";
import Headline2 from "../../../components/typography/headline2";

const Escort = () => {
    return (
        <div className="support">
                <Link onClick={() => window.scrollTo(0, 0)} to="/services"><ButtonArrow left transparent>Вернуться к услугам</ButtonArrow></Link>
            <section className="mobile-width service__header">
                <Headline2>Психологическое сопровождение</Headline2>
            </section>
            <img width={"100%"} src={escort} />

            <section className="mobile-width">
                    <Regular>Услуга психологического сопровождения предполагает оперативное участие психолога в ситуациях, в которых человеку сложно справиться с обстоятельствами, или он не может самостоятельно найти решение.</Regular>
                    <Regular>Важная особенность услуги: участие психолога происходит в формате «здесь и сейчас» по факту возникновения ситуаций или вопросов.</Regular>
                    <Regular>То есть, при возникновении запроса, клиент может сразу адресовать его психологу и получить рекомендации, составить план решения, терапевтическую и моральную поддержку.</Regular>
                    <Regular>Главные преимущества услуги — оперативность и возможность получения помощи психолога в нерабочее время и в выходные дни.</Regular>
                    <Regular>Поэтому, в 100% случаев психологическое сопровождение реализуется с помощью средств мобильной связи — аудио и текстовые сообщения в мессенджерах.</Regular>
                    <Regular>Услуга предоставляется в формате месячного абонемента. Чтобы узнать подробности и стоимость услуги «Психологическая поддержка» можно написать мне в WhatsApp.</Regular>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Button onClick={() => window.open('https://t.me/MaryiaMazur', '_self')}>Записаться</Button>
                </div>
            </section>
        </div>
    );
};

export default Escort;
