import React, {useEffect} from 'react';
import '../style/articles.scss'
import carnage from '../images/films/carnage.jfif'
import Marley from '../images/films/Marley_me.jfif'
import hopeSprings from '../images/films/hope_springs.jfif'
import proposal from '../images/films/proposal.jfif'
import descendants from '../images/films/descendends.jfif'
import vow from '../images/films/vow.jfif'
import relatives from '../images/films/relatives.jfif'
import bigFish from '../images/films/bigFish.jfif'
import family from '../images/films/family.jfif'
import november from '../images/films/november.jfif'
import Headline2 from "../components/typography/headline2";
import Film from "../components/films/Film";
import Book from "../components/books/Book";
import book1 from '../images/books/book1.jpg'
import book2 from '../images/books/Mot_book-2.jpg'
import book3 from '../images/books/Mot_book-3.jpg'
import book4 from '../images/books/Mot_book-4.jpg'
import book5 from '../images/books/Mot_book-5.jpg'
import book6 from '../images/books/Mot_book-6.jpg'
import book7 from '../images/books/Mot_book-7.jpg'
import book8 from '../images/books/Mot_book-8.jpg'
import book9 from '../images/books/Duh_book-1.jpg'
import book11 from '../images/books/Duh_book-3.jpg'
import book12 from '../images/books/Duh_book-4.jpg'
import book14 from '../images/books/Mogu_book-1.jpg'
import book15 from '../images/books/Mogu_book-2.jpg'
import book16 from '../images/books/Mogu_book-3.jpg'
import book17 from '../images/books/Mogu_book-4.jpg'
import book18 from '../images/books/Mogu_book-5.jpg'
import { Link } from 'react-router-dom';
import Subtitle from 'components/typography/subtitle';



const Articles = () => {


    return (
        <div className='articles articles-width'>
            <section className='articles-books'>
                <Headline2>Статьи</Headline2>
                <Link onClick={() => window.scrollTo(0, 0)} to="/articles/love-and-narcissism"><Subtitle>Любовь и Нарциссизм</Subtitle></Link>
                <Link onClick={() => window.scrollTo(0, 0)} to="/articles/psycho-dream"><Subtitle>Психоаналитическая интерпретация сна</Subtitle></Link>
            </section>
            <section className='articles-books'>
                <Headline2>Книги</Headline2>
                <div className='articles-books__container'>
                    <Book
                        image={book1}
                        headline='Атлант расправил плечи'
                        author='Айн Рэнд'
                    />
                    <Book
                        image={book2}
                        headline='Бегущая с волками'
                        author='Кларисса Пинкола Эстес'
                    />
                    <Book
                        image={book3}
                        headline='Невроз и личностный рост'
                        author='Карен Хорни'
                    />
                    <Book
                        image={book4}
                        headline='Лучше поздно, чем никогда'
                        author='Барбара Шер'
                    />
                    <Book
                        image={book5}
                        headline='Пять языков любви'
                        author='Гэри Чемпен'
                    />
                    <Book
                        image={book6}
                        headline='Бабочка в кулаке, или неуловимый оргазм'
                        author='Наталья Терещенко'
                    />
                    <Book
                        image={book7}
                        headline='Мужчине 40'
                        author='Леонид Кроль'
                    />
                    <Book
                        image={book8}
                        headline='Будущее Отца'
                        author='Жан-Пьер Винтер'
                    />
                    <Book
                        image={book9}
                        headline='Радикальное Прощение'
                        author='Колин Типпинг'
                    />
                    <Book
                        image={book11}
                        headline='Радикальная Любовь'
                        author='Колин Типпинг'
                    />
                    <Book
                        image={book12}
                        headline='Радикальное Прощение: Родители и дети'
                        author='Колин Типпинг'
                    />
                    <Book
                        image={book14}
                        headline='Теория каст и ролей'
                        author='Алекс Крол'
                    />
                    <Book
                        image={book15}
                        headline='Гении и аутсайдеры'
                        author='Малкольм Гладуэлл'
                    />
                    <Book
                        image={book16}
                        headline='ВСя правда обо мне'
                        author='Эмбер Рэй'
                    />
                    <Book
                        image={book17}
                        headline='Важные Годы'
                        author='Нэг Джей'
                    />
                    <Book
                        image={book18}
                        headline='Живи с чувством'
                        author='Даниэлла Лапорт'
                    />

                </div>

            </section>
            <section className='articles-films'>
                <Headline2>Фильмы</Headline2>
                <div className='articles-films__container'>
                    <Film
                        link='https://www.kinopoisk.ru/film/557837/'
                        image={carnage}
                        rightImage
                        overlayText='Смотреть на Kinopoisk.ru'
                        headline='Резня (2011)'
                        englishHeadline='Carnage'
                        genre='Драма / Комедия'
                        description='Ссоры детей обычно заканчиваются оплеухами или шумными драками.
                            У взрослых все гораздо хуже — их конфликты могут перерасти в настоящую войну.
                            Задиры и задаваки Захари и Итан даже не подозревали, что натворила их школьная вражда.
                            Сами того не желая, они разбудили древнего бога резни, который заставил расплачиваться за проделки мальчишек их родителей.'
                    />
                    <Film
                        link='https://www.kinopoisk.ru/film/279850/'
                        image={Marley}
                        overlayText='Смотреть на Kinopoisk.ru'
                        headline='Марли и я (2008)'
                        englishHeadline='Marley & Me'
                        genre='Драма / Комедия / Семейный'
                        description='Молодой журналист со своей женой переезжает в другой город на новое место работы.
                            У них грандиозные планы: покупка дома, дети... Но сначала они заводят собаку.
                            Кто бы мог подумать, что именно она станет главным испытанием в их жизни.'
                    />
                    <Film
                        link='https://www.kinopoisk.ru/film/484523/'
                        image={hopeSprings}
                        overlayText='Смотреть на Kinopoisk.ru'
                        headline='Весенние надежды (2012)'
                        englishHeadline='Hope Springs'
                        genre='Драма / Мелодрама / Комедия'
                        description='Главные герои фильма — супружеская пара, находящаяся в браке более 30 лет
                            и пытающаяся при помощи доктора Фелда, занимающегося проблемами семейных союзов, разжечь угасшие отношения.'
                        rightImage
                    />
                    <Film
                        link='https://www.kinopoisk.ru/film/321842/'
                        image={proposal}
                        overlayText='Смотреть на Kinopoisk.ru'
                        headline='Предложение (2009)'
                        genre='Драма / Мелодрама / Комедия'
                        englishHeadline='The Proposal'
                        description='Главная героиня фильма – ответственная начальница, которой грозит высылка в Канаду.
                            Ради того, чтобы избежать ссылки в край озер, героиня готова на все – даже фиктивно выскочить замуж за своего молодого ассистента...'
                    />
                    <Film
                        link='https://www.kinopoisk.ru/film/395369/'
                        image={descendants}
                        overlayText='Смотреть на Kinopoisk.ru'
                        headline='Потомки (2011)'
                        englishHeadline='The Descendants'
                        genre='Драма'
                        description='Мэтт Кинг владеет большими землями на Гавайях, но жизнь его подобна аду: любимая жена в коме, отношения с дочками не ладятся, кредиторы требуют продать землю.
                            Вдобавок ко всему герой узнает, что последние несколько лет супруга изменяла ему…'
                        rightImage
                    />
                    <Film
                        link='https://www.kinopoisk.ru/film/506296/'
                        image={vow}
                        overlayText='Смотреть на Kinopoisk.ru'
                        headline='Клятва (2012)'
                        genre='Драма / Мелодрама'
                        englishHeadline='The Vow'
                        description='Во время медового месяца Пейдж и Лео попадают в автокатастрофу.
                        Пока жена находится в коме, Лео не отходит от ее кровати ни на час, но возвращение девушки к жизни омрачено потерей памяти - она не узнает любимого и не помнит об их романе.
                        И тогда Лео решает заново завоевать сердце Пейдж.'
                    />
                    <Film
                        link='https://www.kinopoisk.ru/film/42115/'
                        image={relatives}
                        overlayText='Смотреть на Kinopoisk.ru'
                        headline='Родня (1981)'
                        genre='Драма / Комедия'
                        description='Фильм в иронической манере повествует о сложных взаимоотношениях близких друг другу людей. Главная героиня в простоте душевной пытается «склеить» распавшуюся семью любимой дочери.
                        Действуя из самых добрых побуждений, она не в состоянии понять, почему ее вмешательство вызывает столь бурный протест…'
                        rightImage/>
                    <Film
                        link='https://www.kinopoisk.ru/film/9617/'
                        image={bigFish}
                        overlayText='Смотреть на Kinopoisk.ru'
                        headline='Большая рыба (2003)'
                        englishHeadline='Big Fish'
                        genre='Фэнтэзи / Драма / Мелодрама / Приключения'
                        description='В основу этой приключенческой ленты положен роман Дэниела Уоллеса «Большая рыба: роман мифических пропорций».
                        Умирает отец, о жизни которого сын хочет узнать как можно больше, собирая истории о нем.
                        Сын пытается воссоздать ускользающую жизнь отца в серии легенд и мифов, которые тот придумывает на основе разрозненных фактов.
                        Взлеты и падения в жизни человека в итоге предстают в неожиданном ракурсе.'
                    />
                    <Film
                        link='https://www.kinopoisk.ru/film/43007/'
                        image={family}
                        overlayText='Смотреть на Kinopoisk.ru'
                        headline='По семейным обстоятельствам (1978)'
                        genre='Драма / Комедия'
                        description='Женщина средних лет решает разменять свою квартиру, где она живет вместе с семьей дочери. Но встреча с обаятельным мужчиной благополучно разрешает «квартирный вопрос»:
                        она переселяется к избраннику и на собственном опыте узнает, что значит жить с родителями.'
                        rightImage
                    />
                    <Film
                        link='https://www.kinopoisk.ru/film/3795/'
                        image={november}
                        overlayText='Смотреть на Kinopoisk.ru'
                        headline='Сладкий ноябрь (2001)'
                        englishHeadline='Sweet November'
                        genre='Драма / Мелодрама'
                        description='Рекламный агент-трудоголик Нельсон Мосс быстро мчится по жизни и не представляет себе ничего другого. Но живая и эксцентричная Сара Дивер, наполовину соблазнительница, наполовину философ, вполне представляет.
                        Если у неё получится, Нельсон станет её новой победой в серии исправленных людских судеб.'
                    />
                </div>
            </section>
            <section>
                <Headline2>Видео</Headline2>
                <iframe width="100%" height="712" src="https://www.youtube.com/embed/oT_HIJAIpFM" title="2005 - Свято"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </section>
        </div>
    );
};

export default Articles;
