import React, {useContext} from 'react';
import "../../style/pages/home.scss";
import mariya_1 from "../../images/mariya/mariya_1.png";
import mariya_2 from "../../images/mariya/mariya_2.png";
import image_1 from "../../images/time.png";
import image_2 from "../../images/chess.png";
import Headline1 from "../../components/typography/headline1";
import Subtitle from "../../components/typography/subtitle";
import Button from "../../components/buttons/button";
import Headline2 from "../../components/typography/headline2";
import Icons from "../../components/Icons/Icons";
import Introduction from "../../components/Introduction/Introduction";
import Headline3 from "../../components/typography/headline3";
import ButtonArrow from "../../components/buttons/button-arrow";
import Regular from "../../components/typography/regular";
import Feedbacks from "../../components/Feedbacks/Feedbacks";
import {Link} from "react-router-dom";
import Service from "../../components/Service/Service";
import online_consultations from "../../images/services/online-consultations.png"
import private_ from "../../images/services/private.png"
import escort from "../../images/services/escort.png"
import coaching from "../../images/services/coaching.png"
import FormModal from "../../components/Form/FormModal";
import IntroductionMobile from "../../components/mobile/IntroductionMobile";
import FeedbacksMobile from "./FeedbacksMobile";
import ServiceMobileCard from "../../components/Service/ServiceMobileCard";
import {colors} from "../../style/_variables";

const Home = () => {
    const [modal, setModal] = React.useState(false);

    const openModal = () => {
        setModal(true);
    }

    const closeModal = () => {
        setModal(false);
    }
    return (
        <div className="home">
            {modal && <FormModal closeModal={closeModal} />}
            <IntroductionMobile image={mariya_1}>
                <Headline1 color={colors.white}>Ваш Психолог</Headline1>
                <Subtitle color={colors.white}>Меня зовут Мария Мазур. Рада, что Вы зашли на мой сайт. Я практикующий психолог, коуч. За плечами имею большой опыт практики консультирования. Постоянно повышаю свою квалификацию.</Subtitle>
                <Subtitle color={colors.white}>На моих консультациях Вы получите поддержку и понимание, комфорт в общении и возможность говорить на любые темы. В своей работе я использую самые эффективные современные техники.</Subtitle>
                <Button onClick={() => window.open('https://t.me/MaryiaMazur', '_self')}>Записаться</Button>
                <Icons white/>
            </IntroductionMobile>
            <section className="home__goal mobile-width">
                <Headline2>Цель психологической помощи — <Regular style={{fontSize: '40px', lineHeight: '48px', margin: 0}}>исправить текущую проблемную ситуацию и предотвратить её повторения</Regular></Headline2>
            </section>
            <img src={mariya_2} style={{width: '100%'}}/>
            <div className='mobile-width'>
                <Headline3>С какими запросами я работаю:</Headline3>
                <Subtitle>Отсутствие взаимопонимания, тяжелое расставание, критика и неудовлетворенность, конфликты, обиды, измена партнера, созависимость</Subtitle>
                <Subtitle sb>Отношения в паре</Subtitle>
                <Subtitle sb>Работа с эмоциями</Subtitle>
                <Subtitle>Отчаяние и растерянность, панические атаки, тревога, бессонница, горе и переживание утраты, обида, в том числе хронические обиды и зависть, страхи и фобии, стресс, перенапряжение, эмоциональное выгорание</Subtitle>
                <Subtitle sb>Психологическое сопровождение</Subtitle>
                <Subtitle>В трудных жизненных ситуациях и чрезвычайных ситуациях, опасных для жизни и здоровья человека</Subtitle>
                <Subtitle sb>Семья</Subtitle>
                <Subtitle>Супружеские отношения, неудовлетворенность браком, семейные конфликты, кризисы в отношениях, работа с разводящимися парами, отношения с родителями во взрослом возрасте (обиды, взаимное непонимание, противостояние, ссоры)</Subtitle>
                <Subtitle sb>Возрастные кризисы</Subtitle>
                <Subtitle>Пройти переоценку жизненного опыта безопасно и с выгодой для себя. Построить новую картину мира</Subtitle>
                <Subtitle sb>Сексуальные проблемы в отношениях</Subtitle>
                <Subtitle sb>Послеродовая депрессия</Subtitle>
            </div>
            <img src={image_1} style={{width: '100%'}}/>
            <img src={image_2} style={{width: '100%'}}/>
            <div className='mobile-width'>
                <Headline2>Страшно принять решение и выбрать психолога?</Headline2>
                <Subtitle sb>Это нормально</Subtitle>
                <Subtitle>Каждый человек хочет, чтобы и время, и деньги, инвестированные в терапию, принесли отдачу.</Subtitle>
                <Subtitle>80% моих клиентов, получили именно то, что искали, и остались со мной до успешного окончания терапии.</Subtitle>
                <Subtitle>Для вашей ситуации я смогу подобрать наиболее эффективные методики и формат терапии.</Subtitle>
                <Subtitle sb>Первая консультация продолжительностью 30 минут ‒ бесплатно</Subtitle>
                <div><Button onClick={openModal}>Заполнить анкету</Button></div>
            </div>
            <section className="home__feedbacks mobile-width">
                <Headline2>Отзывы</Headline2>
                <div className="feedbacks__header">
                    <Subtitle>Публикуются с согласия клиентов</Subtitle>

                </div>
                <Link onClick={() => window.scrollTo(0, 0)}  to='/feedbacks' className='button-feedbacks'>
                    <ButtonArrow transparent><Regular sb>Больше отзывов</Regular></ButtonArrow>
                </Link>
                <FeedbacksMobile />
            </section>
            <section className="mobile-width">
                <Headline2>Формат оказания психологической помощи</Headline2>
                <Link onClick={() => window.scrollTo(0, 0)}  to='/services' className='button-feedbacks'>
                    <ButtonArrow transparent><Regular sb>Смотреть все услуги</Regular></ButtonArrow>
                </Link>
            </section>
            <section className="home-width home__services">
                <ServiceMobileCard
                    image={online_consultations}
                    path={'/services/online_consultations'}
                    serviceName="Онлайн-консультации"
                />
                <ServiceMobileCard
                    image={private_}
                    path={'/services/private'}
                    serviceName="Личный прием психолога"
                />
                <ServiceMobileCard
                    image={escort}
                    path={'/services/escort'}
                    serviceName="Психологическое сопровождение"
                />
                <ServiceMobileCard
                    image={coaching}
                    path={'/services/coaching'}
                    serviceName="Личный коучинг"
                />
            </section>
        </div>
    );
};

export default Home;
