import React from 'react';
import {Link} from "react-router-dom";
import ButtonArrow from "../../components/buttons/button-arrow";
import Headline1 from "../../components/typography/headline1";
import Headline3 from "../../components/typography/headline3";
import Subtitle from "../../components/typography/subtitle";
import Regular from "../../components/typography/regular";
import destructiveImg from "../../images/servicePages/destructive.png";
import Button from "../../components/buttons/button";
import {CountryContext} from "../../contexts/countryContext";

const Destructive = () => {
    const { destructive } = React.useContext(CountryContext);


    return (
        <div className="destructive">
            <section className="service-width service__header">
                <Link onClick={() => window.scrollTo(0, 0)} to="/services"><ButtonArrow left transparent>Вернуться к услугам</ButtonArrow></Link>
                <Headline1>Индивидуальная консультация с проживанием деструктивных эмоций online/offline</Headline1>
                <div className="service__price">
                    <Headline3>Стоимость {destructive}.</Headline3>
                    <Subtitle>Продолжительность: <b>1 час 30 минут</b></Subtitle>
                </div>
            </section>
            <section className="service-width service__main">
                <div className="service__main-left">
                        <Regular>Индивидуальная консультация с проживанием деструктивных эмоций, с применением методов крикотерапии, двигательной терапии, дыхательных практик и научением техникам самопомощи.
                        </Regular>
                        <Regular>— Тревожные и панические расстройства, страхи и фобии;</Regular>
                        <Regular>— Пережитые катастрофы, насилие, нападения и прочие травмирующие события, ПТСР;</Regular>
                        <Regular>— Горе и утрата;</Regular>
                        <Regular>— Сексуальные дисфункции;</Regular>
                        <Regular>— Зависимости;</Regular>
                        <Regular>— Депрессия;</Regular>
                        <Regular>— Самоповреждающее поведение;</Regular>
                        <Regular>— Расстройства пищевого поведения;</Regular>
                        <Regular>— Соматические нарушения (психосоматика).</Regular>
                </div>
                <div className="service__main-right">
                    <img width={"100%"} src={destructiveImg} />
                    <Button onClick={() => window.open('https://t.me/MaryiaMazur', '_self')}>Записаться</Button>
                </div>
            </section>
        </div>
    );
};

export default Destructive;
