import React from 'react';
import {Link} from "react-router-dom";
import ButtonArrow from "../../components/buttons/button-arrow";
import Headline1 from "../../components/typography/headline1";
import Headline3 from "../../components/typography/headline3";
import Subtitle from "../../components/typography/subtitle";
import Regular from "../../components/typography/regular";
import online_consultations from "../../images/servicePages/online_consultations.png";
import Button from "../../components/buttons/button";

const OnlineConsultations = () => {
    return (
        <div className="support">
            <section className="service-width service__header">
                <Link onClick={() => window.scrollTo(0, 0)} to="/services"><ButtonArrow left transparent>Вернуться к услугам</ButtonArrow></Link>
                <Headline1>Онлайн-консультации</Headline1>
            </section>
            <section className="service-width service__main">
                <div className="service__main-left">
                    <Regular>Консультации психолога помогают не только изменить Вашу жизнь к лучшему, но и понять себя и свой внутренний мир.
                        Консультация — это метод взаимодействия, в рамках которого вы сможете достигнуть цели, раскрыть свой потенциал, а также решить вопросы в разных сферах жизни. Из практики можно отметить, что онлайн-консультации ничуть не уступают по эффективности очной встрече. </Regular>
                    <Regular style={{marginTop: "5%"}}>Плюсы: </Regular>
                    <Regular>УДОБСТВО — вам не нужно тратить время и силы на дорогу.</Regular>
                    <Regular>ВРЕМЯ — вы выбираете удобный для себя график.</Regular>
                    <Regular>УЕДИНЕНИЕ — Наедине с собой и своими мыслями.</Regular>
                    <Regular>КОМФОРТ — Спокойная и уютная обстановка поможет вам лучше настроиться на занятия.</Regular>
                    <Regular>Стоит отметить, что не прямой контакт с психологом, а удалённый даёт возможность лучшему усвоению информации. А это значит, что консультация пройдёт наилучшим способом и поможет вам в дальнейшей жизни.</Regular>
                    <Regular sb>Виды онлайн-консультаций:</Regular>
                    <div className="service__hrefs">
                        <Link onClick={() => window.scrollTo(0, 0)} to="/services/family"><ButtonArrow transparent>Семейная консультация</ButtonArrow></Link>
                        <Link onClick={() => window.scrollTo(0, 0)} to="/services/destructive"><ButtonArrow transparent>Консультация с проживанием деструктивных эмоций</ButtonArrow></Link>
                        <Link onClick={() => window.scrollTo(0, 0)} to="/services/support"><ButtonArrow transparent>Поддерживающая консультация</ButtonArrow></Link>
                    </div>
                </div>
                <div className="service__main-right">
                    <img width={"100%"} src={online_consultations} />
                    <Button onClick={() => window.open('https://t.me/MaryiaMazur', '_self')}>Записаться</Button>
                </div>
            </section>
        </div>
    );
};

export default OnlineConsultations;
