import React from 'react';
import logo from '../../images/logo.png'
import {Link} from "react-router-dom";

const Logo = () => {
    return (
        <div className="logo">
           <Link onClick={() => window.scrollTo(0, 0)} to="/home"><img alt="Maryia Mazur" src={logo} /></Link>
        </div>
    );
};

export default Logo;
