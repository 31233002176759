import styled from "styled-components";

const Headline3 = styled.h1.attrs({
    className: "headline3",
})`
    color: ${(props) => props.color};
    cursor: ${(props) => (props.clickable ? "pointer" : "initial")};
    ${(props) => (props.disabled ? "cursor: not-allowed;" : "")}
`;
export default Headline3;
