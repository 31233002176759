import React from 'react';
import FeedbackCard from "./FeedbackCard";
import Ekaterina from '../../images/feedback/Katerina.png';
import simon from '../../images/feedback/simon.png';
import Ann from '../../images/feedback/Ann.png';

const Feedbacks = () => {
    return (
        <div className="feedbacks__main">
            <div className="feedbacks__column">
                <FeedbackCard name="Валентина">
                    Мария, огромное спасибо за игру «Сатори». Вышла из кабинета с позитивным настроением. Вы помогли мне разобраться в себе и затронуть даже самые далекие глубины моего сознания. Всем рекомендую! Спасибо
                </FeedbackCard>
                <FeedbackCard image={Ekaterina} name="Екатерина">
                    Мария, большое спасибо за помощь и поддержку. Пройдя онлайн-программу “Радикальное прощение”, я перестала думать о прошлои, негативные мысли меня покинули. Я перестала винить себя...
                </FeedbackCard>
            </div>
            <div className="feedbacks__column">
                <FeedbackCard image={simon} name="Семён">
                    Здравствуйте, Мария Хочу поблагодарить вас от всего сердца за консультацию. Очень непросто найти своего человека в сфере психологии, это особенно деликатная тема. Я очень рад...
                </FeedbackCard>
                <FeedbackCard name="Вероника">
                    Мария, хочу поблагодарить тебя за помощь в прохождении программы «Радикальное прощение». До этой программы у меня было куча претензий и обид к себе и окружающему миру. Я перекладывала ответственность за свои эмоции и поступки на других людей. Винила себя за свои эмоции и не могла их правильно проживать. После этой…
                </FeedbackCard>
            </div>
            <div className="feedbacks__column">
                <FeedbackCard image={Ann} name="Анна">
                    Мария, хочу поблагодарить тебя за то, что ты привезла в наш город технологию РП. Мне посчастливилось быть первым клиентом. У меня сегодня было последнее занятие. Ююхуу, я сделала жто и горжусь этим. Останавливаться не собираюсь, буду работать дальше, до тех пор пока не пройду все задания, все уровни (прошение второго родителя, портнера, себя и т.д.). Непросто найти время иногда для ...
                </FeedbackCard>
            </div>
        </div>
    );
};

export default Feedbacks;
