import React from "react";
import { QRCodeCanvas } from "qrcode.react";
import { BeatLoader } from "react-spinners";
import { colors } from "../../style/_variables";
import Subtitle from "components/typography/subtitle";
import services from "utils/services";
import Button from "components/buttons/button";
import success from "../../images/svg/success.svg";
import error from "../../images/svg/error.svg";

const PaymentFinal = ({ dispatch, serviceForPayment, mobile }) => {
  const [QRcode, setQRcode] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [paymentMessage, setPaymentMessage] = React.useState({
    name: "",
    status: 0,
  });
  React.useEffect(() => {
    let timer = null;
    let dispatchTimer = null;
    const sum = Number(serviceForPayment.sum.match(/[0-9]{1,5}/)[0]);
    let isMounted = true;
    const checkPayment = async (paymentId) => {
      await fetch(
        `https://cashboxapi.o-plati.by/ms-pay/pos/payments/${paymentId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            regNum: "OPL000007608",
            password: "Ytw3z7Q2",
          },
        }
      )
        .then((res) => res.json())
        .then(({ status }) => {
          switch (status) {
            case 0:
              if (!isMounted) return;
              timer = setTimeout(() => {
                checkPayment(paymentId);
              }, 3000);
              break;
            case 1:
              setLoading(false);
              setPaymentMessage({
                name: ` оплачена успешно!`,
                status: 1,
              });
              break;
            case 2:
              setLoading(false);
              setPaymentMessage({
                name: `Платеж не подтвержден. Повторите попытку.`,
                status: 2,
              });
              dispatchTimer = setTimeout(() => {
                dispatch({ type: "OPEN_FIRST" });
              }, 5000);
              break;
            case 3:
              setLoading(false);
              setPaymentMessage({
                name: `Недостаточно средств. Повторите попытку.`,
                status: 3,
              });
              dispatchTimer = setTimeout(() => {
                dispatch({ type: "OPEN_FIRST" });
              }, 5000);
              break;
            case 4:
              setLoading(false);
              setPaymentMessage({
                name: "Время оплаты истекло. Выберите услугу заново.",
                status: 4,
              });
              dispatchTimer = setTimeout(() => {
                dispatch({ type: "OPEN_FIRST" });
              }, 5000);
              break;
            case 5:
              setLoading(false);
              setPaymentMessage({
                name: "Время оплаты истекло. Выберите услугу заново.",
                status: 4,
              });
              dispatchTimer = setTimeout(() => {
                dispatch({ type: "OPEN_FIRST" });
              }, 5000);
              break;
            default:
              setLoading(false);
              setPaymentMessage({
                name: "Произошла ошибка. Повторите попытку.",
                status: 5,
              });
              dispatchTimer = setTimeout(() => {
                dispatch({ type: "OPEN_FIRST" });
              }, 5000);
          }
        });
    };

    fetch("https://cashboxapi.o-plati.by/ms-pay/pos/webPayments", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        regNum: "OPL000007608",
        password: "Ytw3z7Q2",
      },
      body: JSON.stringify({
        orderNumber: serviceForPayment.orderNumber,
        sum,
        details: {
          items: [
            {
              type: 1,
              name: services.orderNumberToName[serviceForPayment.orderNumber],
              quantity: 1,
              price: sum,
              cost: sum,
            },
          ],
          amountTotal: sum,
          footerInfo: "Психолог Мария Мазур",
        },
      }),
    })
      .then((res) => res.json())
      .then(({ dynamicQR, paymentId }) => {
        setQRcode(dynamicQR);
        checkPayment(paymentId);
      });

    return () => {
      isMounted = false;
      clearTimeout(timer);
      clearTimeout(dispatchTimer);
    };
  }, [dispatch, serviceForPayment.orderNumber, serviceForPayment.sum]);

  if (mobile) {
    return (
      <>
        {QRcode ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div>
              <Subtitle style={{ fontWeight: 200 }}>
                Выбранная услуга -{" "}
                <span style={{ fontWeight: 400 }}>
                  {services.orderNumberToName[serviceForPayment.orderNumber]}
                </span>{" "}
              </Subtitle>
              <Subtitle style={{ fontWeight: 200 }}>
                Сумма к оплате -{" "}
                <span style={{ fontWeight: 400 }}>{serviceForPayment.sum}</span>{" "}
              </Subtitle>
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    flexDirection: "column",
                  }}
                >
                  <Subtitle
                    style={{
                      textAlign: "center",
                      width: "100%",
                      marginTop: "30px",
                    }}
                  >
                    Ожидаем подтверждение оплаты...
                  </Subtitle>
                  <BeatLoader cssOverride={{ alignSelf: "center" }} size={20} />
                </div>
              ) : services.errorStatuses.includes(paymentMessage.status) ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <Subtitle
                    style={{
                      marginTop: "30px",
                      color: colors.error,
                    }}
                  >
                    {paymentMessage.name}
                  </Subtitle>
                  <div style={{ width: "15%" }}>
                    <img src={error} />
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <Subtitle
                    style={{
                      marginTop: "30px",
                      color: colors.black,
                      textAlign: "center  ",
                    }}
                  >
                    Услуга
                    <br />
                    <span style={{ fontWeight: 600 }}>
                      {
                        services.orderNumberToName[
                          serviceForPayment.orderNumber
                        ]
                      }
                    </span>
                    <br />
                    {paymentMessage.name}
                  </Subtitle>
                  <div style={{ width: "15%" }}>
                    <img src={success} />
                  </div>
                </div>
              )}
            </div>
            {services.errorStatuses.includes(paymentMessage.status) ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <Subtitle
                  style={{
                    marginTop: "30px",
                    color: colors.error,
                  }}
                >
                  {paymentMessage.name}
                </Subtitle>
                <div style={{ width: "15%" }}>
                  <img src={error} />
                </div>
              </div>
            ) : (
              <a
                href={`https://getapp.o-plati.by/map/?app_link=${QRcode}`}
                target="_blank"
                rel="noreferrer"
              >
                <Button>Оплатить</Button>
              </a>
            )}
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <BeatLoader color={colors.blue} />
          </div>
        )}
      </>
    );
  } else {
    return (
      <>
        {QRcode ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <div>
              <Subtitle style={{ fontWeight: 200 }}>
                Выбранная услуга -{" "}
                <span style={{ fontWeight: 400 }}>
                  {services.orderNumberToName[serviceForPayment.orderNumber]}
                </span>{" "}
              </Subtitle>
              <Subtitle style={{ fontWeight: 200 }}>
                Сумма к оплате -{" "}
                <span style={{ fontWeight: 400 }}>{serviceForPayment.sum}</span>{" "}
              </Subtitle>

              {loading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    flexDirection: "column",
                  }}
                >
                  <Subtitle
                    style={{
                      textAlign: "center",
                      width: "100%",
                      marginTop: "30px",
                    }}
                  >
                    Ожидаем подтверждение оплаты...
                  </Subtitle>
                  <BeatLoader cssOverride={{ alignSelf: "center" }} size={20} />
                </div>
              ) : services.errorStatuses.includes(paymentMessage.status) ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <Subtitle
                    style={{
                      marginTop: "30px",
                      color: colors.error,
                    }}
                  >
                    {paymentMessage.name}
                  </Subtitle>
                  <div style={{ width: "15%" }}>
                    <img src={error} />
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <Subtitle
                    style={{
                      marginTop: "30px",
                      color: colors.black,
                      textAlign: "center  ",
                    }}
                  >
                    Услуга
                    <br />
                    <span style={{ fontWeight: 600 }}>
                      {
                        services.orderNumberToName[
                          serviceForPayment.orderNumber
                        ]
                      }
                    </span>
                    <br />
                    {paymentMessage.name}
                  </Subtitle>
                  <div style={{ width: "15%" }}>
                    <img src={success} />
                  </div>
                </div>
              )}
            </div>
            {services.errorStatuses.includes(paymentMessage.status) ? (
              ""
            ) : (
              <QRCodeCanvas level="H" size={256} value={QRcode} />
            )}
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <BeatLoader color={colors.blue} />
          </div>
        )}
      </>
    );
  }
};

export default PaymentFinal;
