export const Prices = {
  Belarus: {
    COUNTRY: "Belarus",
    individual: "70 бел. руб",
    destructive: "100 бел. руб",
    support: "60 бел. руб",
    family: "120 бел. руб",
    forgiveness: {
      pro: "400 бел. руб",
      vip: "500 бел. руб",
    },
    weight: "400 бел. руб",
    money: {
      pro: "350 бел. руб",
      vip: "450 бел. руб",
    },
    yourself: {
      pro: "400 бел. руб",
      vip: "500 бел. руб",
    },
    satori: {
      individual: "100 бел. руб",
      group: "50 бел. руб",
    },
    sorrow: {
      online: "900 бел. руб",
      offline: "900 бел. руб",
    },
  },
  Russia: {
    COUNTRY: "Russia",
    individual: "2.000 рос. руб",
    destructive: "3.000 рос. руб",
    support: "1.000 рос. руб",
    family: "2.000 рос. руб",
    forgiveness: {
      pro: "8.500 рос. руб",
      vip: "15.000 рос. руб",
    },
    weight: "8.500 рос. руб",
    money: {
      pro: "8.500 рос. руб",
      vip: "10.500 рос. руб",
    },
    yourself: {
      pro: "8.500 рос. руб",
      vip: "10.500 рос. руб",
    },
    satori: {
      individual: "3.000 рос. руб",
      group: "",
    },
    sorrow: {
      online: "10.000 рос. руб",
      offline: "",
    },
  },
  USA: {
    COUNTRY: "USA",
    individual: "30$",
    destructive: "50$",
    support: "15$",
    family: "30$",
    forgiveness: {
      pro: "150$",
      vip: "250$",
    },
    weight: "150$",
    money: {
      pro: "150$",
      vip: "180$",
    },
    yourself: {
      pro: "150$",
      vip: "180$",
    },
    satori: {
      individual: "50$",
      group: "",
    },
    sorrow: {
      online: "180$",
      offline: "",
    },
  },
};
