import React from 'react';
import Headline1 from "../components/typography/headline1";
import '../style/pages/services.scss';
import Headline3 from "../components/typography/headline3";
import online_consultations from "../images/services/online-consultations.png";
import private_ from "../images/services/private.png";
import Service from "../components/Service/Service";
import destructive from "../images/services/desctructive-emotions.png"
import family from "../images/services/family.png"
import support from "../images/services/support.png"
import satori from "../images/services/satori.png"
import yourself from "../images/services/yourself.png"
import money from "../images/services/money.png"
import forgiveness from "../images/services/forgiveness.png"
import weight from "../images/services/weight.png"
import sorrow from "../images/services/sorrow.png"
import Button from "../components/buttons/button";
import {Link, Outlet} from "react-router-dom";

const Services = () => {
    return (
        <>
            <div className="services">
                <section className="services-width services__services-cards">
                    <Headline1 style={{marginBottom: "5%"}}>Услуги</Headline1>
                    <section className="services__consultations">
                        <Headline3>Консультации</Headline3>
                        <Service
                            path_left="/services/individual"
                            path_right="/services/destructive"
                            image_left={private_}
                            image_right={destructive}
                            serviceName_left="Индивидуальная консультация"
                            serviceName_right="Индивидуальная консультация с проживанием деструктивных эмоций"
                        />
                        <Service
                            path_left="/services/support"
                            path_right="/services/family"
                            image_left={support}
                            image_right={family}
                            serviceName_left="Поддерживающая консультация"
                            serviceName_right="Семейные консультации"
                        />
                    </section>
                    <section className="services__consultations">
                        <Headline3 style={{margin: "5% 0"}}>Онлайн-программы</Headline3>
                        <Service
                            path_left="/services/forgiveness"
                            path_right="/services/weight"
                            image_left={forgiveness}
                            image_right={weight}
                            serviceName_left="Радикальное прощение 21 день"
                            serviceName_right="Онлайн-программа «Вес»"
                        />
                        <Service
                            path_left="/services/money"
                            path_right="/services/yourself"
                            image_left={money}
                            image_right={yourself}
                            serviceName_left="Радикальные деньги"
                            serviceName_right="Радикальное принятие себя"
                        />
                        <Service
                            path_left="/services/satori"
                            path_right="/services/sorrow"
                            image_left={satori}
                            image_right={sorrow}
                            serviceName_left="«Сатори» (Осознание)"
                            serviceName_right="«Скорбь»"
                        />
                    </section>
                </section>
                <section className="services-width services__buttons">
                    <a href="https://mariamazur.ru/oferta.rar" download="oferta.rar"><Button>Договор Оферты</Button></a>
                    <Link to="/contacts#payment"><Button>Способы оплаты</Button></Link>
                </section>

            </div>
            <Outlet />
        </>
    );
};

export default Services;
