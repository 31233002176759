import React from 'react';
import './header-mobile.scss'
import Logo from "../../Logo/Logo";
import ModalMenu from "../../Menu/ModalMenu";
import Burger from "./Burger";

const HeaderMobile = () => {
    const [isOpen, setIsOpen] = React.useState(false);
    return (
        <header className="header-mobile">
            <Logo />
            <Burger setIsOpen={setIsOpen} isOpen={isOpen} />
            <ModalMenu isOpen={isOpen} setIsOpen={setIsOpen}/>
        </header>
    );
};

export default HeaderMobile;
