import React from 'react';


const Introduction = ({image, children, right, center}) => {

    return  right ? <>
        <section className="introduction-right" >
            <div className="introduction__image">
                <img src={image} alt="introduction-image" />
            </div>
            <div className="introduction__text" style={{justifyContent: "center"}}>
                {children}
            </div>
        </section>
    </> : <>
            <section className="introduction" >
                <div className="introduction__text" style={center ? {justifyContent: "center"} : {justifyContent: "flex-end"}} >
                    {children}
                </div>
                <div className="introduction__image">
                    <img src={image} alt="introduction-image" />
                </div>
            </section>
        </>
};

export default Introduction;
