import React from "react";
import { Link } from "react-router-dom";
import ButtonArrow from "../../components/buttons/button-arrow";
import Headline1 from "../../components/typography/headline1";
import Headline3 from "../../components/typography/headline3";
import Regular from "../../components/typography/regular";
import weightImg from "../../images/servicePages/weight.png";
import Button from "../../components/buttons/button";
import { CountryContext } from "../../contexts/countryContext";

const Weight = () => {
  const { weight, COUNTRY } = React.useContext(CountryContext);

  return (
    <div className="weight">
      <section className="service-width service__header">
        <Link onClick={() => window.scrollTo(0, 0)} to="/services">
          <ButtonArrow left transparent>
            Вернуться к услугам
          </ButtonArrow>
        </Link>
        <Headline1>Онлайн-программа "Вес" </Headline1>
        <div className="service__price">
          <Headline3>Стоимость {weight}.</Headline3>
        </div>
      </section>
      <section className="service-width service__main">
        <div className="service__main-left">
          <Regular>
            Психологическая мотивационная программа рассчитана на людей,
            желающих привести в порядок свой вес. А также достичь гармонии со
            своим телом, не прибегая к жёстким ограничениям и изнурительным
            тренировкам, а также сохранить полученный результат. Занятия в
            программе ведутся по 2 направлениям: ваша работа с диетологом и
            работа в программе «Вес» с психологом. Поэтому для достижения
            максимальной эффективности рекомендуется работать в программе «Вес»
            одновременно с назначениями диетолога. Онлайн-программа «Вес» — это
            разработанная мной методика, которая помогает участникам понять суть
            проблемы. Ведь не все понимают, что суть «лишнего веса» глубоко
            спрятана у вас внутри.
          </Regular>
          <Regular sb>Для кого подходит курс?</Regular>
          <Regular>
            Для прохождения курса нет рамок, кроме совершеннолетия. В этом и
            суть онлайн версии.
          </Regular>

          <Regular sb>Онлайн-программа «Вес» подойдёт вам, если вы:</Regular>
          <Regular>— Безуспешно боретесь с лишним весом.</Regular>
          <Regular>— Вы не принимаете своё тело.</Regular>
          <Regular>
            — Вы ощущаете проблемы со здоровьем из-за избыточного веса.
          </Regular>
          <Regular>— Вы постоянно переедаете.</Regular>
          <Regular>
            — После употребления пищи у вас возникают негативные эмоции.
          </Regular>
          <Regular>— У вас идёт колебание веса.</Regular>

          <Regular sb>В основу программы входит проработка:</Regular>
          <Regular>
            Психосоматические причины колебания веса. Выявление вторичных выгод.
          </Regular>
          <Regular>
            — Устранение внутренних детско-родительских конфликтов, влияющих на
            проблему с весом.
          </Regular>
          <Regular>
            — Формирование здоровых границ тела и чувства защищённости и
            безопасности.
          </Regular>
          <Regular>
            — Исцеление травм, связанных с сексуальностью и способностью
            получать удовольствие и удовлетворение.
          </Regular>
          <Regular>
            — Работа со страхом изменений, отпускания лишнего и не пережитых
            потерь.
          </Regular>
          <Regular>
            — Выстраивание контакта со своей силой, уверенностью и здоровой
            агрессией.
          </Regular>
          <Regular>
            — Восстановление своего внутреннего авторитета, весомости и чувства
            собственной ценности.
          </Regular>
          <Regular>
            — Работа, посвящённая целям, мечтам и выстраиванию идеального образа
            себя.
          </Regular>
          {COUNTRY === "Belarus" && <><Headline3>Услуга «Раздели оплату» (200 бел. руб.)</Headline3>
          <Regular>
            Данная услуга предоставляет возможность оплатить онлайн-программу в
            2 платежа. Первый взнос необходимо внести на момент старта
            программы, а вторая часть после окончания курса.
          </Regular></>}
        </div>
        <div className="service__main-right">
          <img width={"100%"} src={weightImg} />
          <Button
            onClick={() => window.open("https://t.me/MaryiaMazur", "_self")}
          >
            Записаться
          </Button>
        </div>
      </section>
    </div>
  );
};

export default Weight;
