import React from 'react';
import Feedbacks from "../components/Feedbacks/Feedbacks";
import Headline1 from "../components/typography/headline1";
import Subtitle from "../components/typography/subtitle";

const FeedbacksPage = () => {
    return (
        <div className="feedbacks">
            <section className="about-width feedbacks__feedbacks">
                <Headline1>Отзывы</Headline1>
                <Subtitle style={{marginBottom: "5%"}}>Публикуются с согласия клиентов</Subtitle>
                <Feedbacks />
            </section>
        </div>
    );
};

export default FeedbacksPage;
