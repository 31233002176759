import React from 'react';
import Description from "../typography/description";
import Subtitle from "../typography/subtitle";

const Book = ({image, headline, author}) => {
    return (
        <div className='book'>
            <img src={image} alt='book-image'/>
            <div className='overlay-book'>
                <div>
                    <Subtitle>{headline}</Subtitle>
                    <Description isForm>{author}</Description>
                </div>
            </div>
        </div>
    );
};

export default Book;
