import React from "react";
import "./footer-mobile.scss";
import Logo from "../Logo/Logo";
import { Link } from "react-router-dom";
import Regular from "../typography/regular";
import { colors } from "../../style/_variables";
import Icons from "../Icons/Icons";
import Description from "../typography/description";
import oplatiLogoBlack from "../../images/oplatiBlack.png";

const FooterMobile = () => {
  return (
    <div className="footer-mobile">
      <Logo />
      <Description
        style={{ textAlign: "center", fontSize: "1.2rem" }}
        color={colors.gray}
      >
        По профессии и по признанию психолог. За плечами имею большой опыт
        практики консультирования. Постоянно повышаю свою квалификацию.
      </Description>
      <Link onClick={() => window.scrollTo(0, 0)} to="/home">
        <Regular color={colors.black}>Главная</Regular>
      </Link>
      <Link onClick={() => window.scrollTo(0, 0)} to="/about">
        <Regular color={colors.black}>Обо мне</Regular>
      </Link>
      <Link onClick={() => window.scrollTo(0, 0)} to="/articles">
        <Regular color={colors.black}>Полезное</Regular>
      </Link>
      <Link onClick={() => window.scrollTo(0, 0)} to="/services">
        <Regular color={colors.black}>Услуги</Regular>
      </Link>
      <Link onClick={() => window.scrollTo(0, 0)} to="/contacts">
        <Regular color={colors.black}>Контакты</Regular>
      </Link>
      <Link onClick={() => window.scrollTo(0, 0)} to="/feedbacks">
        <Regular color={colors.black}>Отзывы</Regular>
      </Link>
      <div className="footer-mobile__contacts">
        <Regular>
          +375 29 549-39-52
          <br />
          psi_mariamazur@mail.ru
        </Regular>
        <Icons modal={true} />
        <div>
          <a
            href="https://www.b17.ru/mazurmaria/?prt=856178"
            height="0"
            width="0"
            target="_blank"
            rel="noreferrer"
          >
            <img src="https://www.b17.ru/img/b17_88x31_b.png" />
          </a>
          <a
            href="https://www.o-plati.by/"
            height="0"
            width="0"
            target="_blank"
            rel="noreferrer"
          >
            <img
              style={{ height: "31px", marginLeft: "10px" }}
              src={oplatiLogoBlack}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default FooterMobile;
