import React from 'react';
import {Link} from "react-router-dom";
import ButtonArrow from "../../../components/buttons/button-arrow";
import Headline1 from "../../../components/typography/headline1";
import Headline3 from "../../../components/typography/headline3";
import Regular from "../../../components/typography/regular";
import satoriImg from "../../../images/servicePages/satori.png";
import Button from "../../../components/buttons/button";
import {CountryContext} from "../../../contexts/countryContext";
import Headline2 from "../../../components/typography/headline2";

const Satori = () => {
    const {satori} = React.useContext(CountryContext);

    return (
        <div className="satori">
                <Link onClick={() => window.scrollTo(0, 0)} to="/services"><ButtonArrow left transparent>Вернуться к услугам</ButtonArrow></Link>
            <section className="mobile-width service__header">
                <Headline2>«Сатори» (Осознание)</Headline2>
                {!satori?.group && <Headline2> Недоступно в вашем регионе! Только оффлайн встречи!</Headline2>}
                <div className="service__price">
                    {satori?.group && <Headline3>Стоимость участия в группе ( 3-5 человек) - {satori?.group}</Headline3>}
                </div>
                <div className="service__price">
                    {satori?.individual && <Headline3>Индивидуальное сопровождение в игре - {satori?.individual}</Headline3>}
                </div>
            </section>
                    <img width={"100%"} src={satoriImg} />
            <section className="mobile-width">
                    <Regular>Слово Сатори – пришло к нам из Японии, в переводе оно означает как «озарение, осознание». Игра посвящена процессу осознания себя и причины многих событий, происходящих с Вами. </Regular>
                    <Regular>Игра «Сатори» помогает понять почему и по какой причине происходили в жизни разные неприятные повторяющиеся ситуации.</Regular>
                    <Regular>Т-игра «Сатори» не только даст Вам ответы на интересующие вопросы, но и научит справляться с негативными ситуациями и отрицательными эмоциями. А также научит жить в гармонии с собой, вернет Вам спокойствие и уверенность в себе. В эту игру можно играть бесконечно, с каждым разом вытаскивая, именно так, из своего подсознания забытые обидные вещи. Ведь даже маленькая обида блокирует энергию, которую можно направить на своё развитие и процветание.</Regular>
                    <Regular>В ходе игры у Вас появится возможность проработать волнующие Вас темы, например:</Regular>
                    <Regular>- Проблемы в отношениях (семья, друзья, бизнес-партнеры, коллеги).</Regular>
                    <Regular>- Карьера.</Regular>
                    <Regular>- Деньги (финансы).</Regular>
                    <Regular>- Целеполагание.</Regular>
                    <Regular>- Лишний вес.</Regular>
                    <Regular>Когда игроки избавляются от старых обид, они начинают светиться изнутри. Игра "Сатори" помогает нам понять, что весь мир вокруг состоит из наших убеждений, но они всего лишь ложные. Их можно увидеть и избавиться от них навсегда, меняя свою жизнь с обычной на яркую и полную приятных моментов.</Regular>
                    <Regular>САТОРИ - пробуждение, озарение, просветление…</Regular>

                    <Regular>Так же и в игре «Сатори» в какой-то момент ты понимаешь почему и для чего происходили те или иные события в жизни…</Regular>
                    <Regular>А еще тебя буквально озаряет, что так больше не хочешь, а хочешь жить по-другому и выбираешь свою новую историю….</Regular>
                    <Regular>В игре понимаешь причины, по которым происходят повторяющиеся неприятные ситуации в жизни…</Regular>
                    <Regular>Замечаешь ограничивающие убеждения, которые влияют и руководят твоими событиями в жизни. В игре можно очень просто освободится от устаревших убеждений…</Regular>
                    <Regular sb>Как же это работает?</Regular>
                    <Regular>Исцеление происходит через сердце, а не через голову, поэтому изменения происходят быстро и остаются надолго! И я это подтверждаю на собственном опыте!
                        За 3-4 часа Игры Вы сможете избавиться от болезненных обид и ограничивающих убеждений, которые управляли Вами многие годы.
                        Это позволит уже во время Игры получить первые неожиданные результаты.</Regular>
                    <Regular>Игра САТОРИ создана по методу Радикального Прощения Колина Типпинга.
                        Помогает ответить на волнующие вопросы и найти выход из сложившейся ситуации. <b>Проводится только в режиме офлайн.</b></Regular>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Button onClick={() => window.open('https://t.me/MaryiaMazur', '_self')}>Записаться</Button>
                </div>
            </section>
        </div>
    );
};

export default Satori;
