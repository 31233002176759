import React from "react";
import "../../style/pages/contacts.scss";
import Headline1 from "../../components/typography/headline1";
import Subtitle from "../../components/typography/subtitle";
import Icons from "../../components/Icons/Icons";
import Button from "../../components/buttons/button";
import Headline2 from "../../components/typography/headline2";
import { Link } from "react-router-dom";
import { CountryContext } from "contexts/countryContext";

const Contacts = () => {
  const { COUNTRY } = React.useContext(CountryContext);
  return (
    <div className="contacts">
      <section className="contacts__map">
        <Headline1 className="mobile-width">Контакты</Headline1>
        <iframe
          src="https://yandex.ru/map-widget/v1/?um=constructor%3Ae8769d56bf425944b72136109d470e52b9be8bda6a65e20fb607c4b19556271a&amp;source=constructor"
          width={`${window.innerWidth}`}
          height="424"
          frameBorder="0"
        />
        <div className="map__wrapper">
          <div className="mobile-width">
            <Subtitle>
              Суворова 127, корпус 5, 3-й этаж, 303 кабинет <br />
              +375 29 549-49-52 <br />
              +375 29 549-49-52 <br /> psi_mariamazur@mail.ru
            </Subtitle>
            <Icons />
            <Button
              style={{ marginTop: "5%" }}
              onClick={() => window.open("https://t.me/MaryiaMazur", "_self")}
            >
              Записаться
            </Button>
          </div>
        </div>
      </section>
      <section id="#payment">
        <Headline2 className="mobile-width">Способы оплаты</Headline2>

       {COUNTRY === "Belarus" ? <><Subtitle className="mobile-width">
          1.{" "}
          <Link onClick={() => window.scrollTo(0, 0)} to={"/payment"}>
            Сервис «Оплати» (по нажатию произойдет переход на страницу оплаты)
          </Link>
        </Subtitle>
        <Subtitle className="mobile-width">
          2. Оплата по реквизитам ИП Мазур Мария Викторовна р/с
          №BY39BLBB30130791214384001001 в ОАО «БЕЛИНВЕСТБАНК» БИК(код банка) —
          BLBBBY2X УНП 791214384
        </Subtitle></> :
        <Subtitle className="mobile-width">
          1. Оплата по реквизитам ИП Мазур Мария Викторовна р/с
          №BY39BLBB30130791214384001001 в ОАО «БЕЛИНВЕСТБАНК» БИК(код банка) —
          BLBBBY2X УНП 791214384
        </Subtitle>} 
      </section>
    </div>
  );
};

export default Contacts;
