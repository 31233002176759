import React, {useEffect, useRef} from 'react';
import Regular from "../typography/regular";
import Headline3 from "../typography/headline3";
import Description from "../typography/description";

const Film = ({rightImage, image, overlayText, headline, englishHeadline, genre, description, mobile, link}) => {
    const ref = useRef(null);
    const getThresholdArray = () => {
        const threshold = []

        for (let i=0; i<=1; i+=0.01) threshold.push(i)

        return threshold
    }
    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    ref.current.style.opacity=entry.intersectionRatio;
                }
            })
        }, {
            threshold: getThresholdArray(),
        })
        observer.observe(ref.current);
    }, [])

    return mobile ? <div className='film'>
            <div className='film-description' style={{marginLeft: 0}} ref={ref}>
                <Headline3>{headline}</Headline3>
                <Regular>{englishHeadline}</Regular>
                <Description isForm>{genre}</Description>
                <Regular>{description}</Regular>
            </div>
            <div className='film-image'>
                <img src={image} alt='film-image'/>
                <a href={link} target='_blank'><div className='overlay'><Regular>{overlayText}</Regular></div></a>
            </div>
    </div> : rightImage ?
        <div className='film'>
            <div className='film-description' style={{marginLeft: 0, marginRight: '20px'}} ref={ref}>
                <Headline3>{headline}</Headline3>
                <Regular>{englishHeadline}</Regular>
                <Description isForm>{genre}</Description>
                <Regular>{description}</Regular>
            </div>
                <div className='film-image'>
                    <img src={image} alt='film-image'/>
                    <a href={link} target='_blank'><div className='overlay'><Regular>{overlayText}</Regular></div></a>
                </div>
        </div>
        : (<div className='film'>
            <div className='film-image'>
                <img src={image} alt='film-image'/>
                <a href={link} target='_blank'><div className='overlay'><Regular>{overlayText}</Regular></div></a>
            </div>
            <div className='film-description' ref={ref}>
                <Headline3>{headline}</Headline3>
                <Regular>{englishHeadline}</Regular>
                <Description isForm>{genre}</Description>
                <Regular>{description}</Regular>
            </div>
        </div>)
};

export default Film;
