import React from "react";
import {Prices} from "./prices";

export const useCountry = () => {
    const [country, setCountry] = React.useState('');
    const { Belarus, Russia, USA } = Prices;

    React.useEffect(() => {
        fetch('https://freeipapi.com/api/json').then(
            r => r.json()
        ).then(({countryName}) => {
            switch(countryName) {
                case 'Belarus':
                    console.log('Current country: ', countryName);
                    setCountry(Belarus);
                    break;
                case 'Russian Federation':
                    console.log('Current country: ', countryName);
                    setCountry(Russia);
                    break;
                default:
                    console.log('Current country: ', countryName);
                    setCountry(USA);
                    break;
            }
        }).catch(() => setCountry(USA));
    }, []);

    return country;
}
