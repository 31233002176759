import React, {useContext} from 'react';
import "../style/pages/home.scss";
import mariya_1 from "../images/mariya/mariya_1.png";
import mariya_2 from "../images/mariya/mariya_2.png";
import image_1 from "../images/time.png";
import image_2 from "../images/chess.png";
import Headline1 from "../components/typography/headline1";
import Subtitle from "../components/typography/subtitle";
import Button from "../components/buttons/button";
import Headline2 from "../components/typography/headline2";
import Icons from "../components/Icons/Icons";
import Introduction from "../components/Introduction/Introduction";
import Headline3 from "../components/typography/headline3";
import ButtonArrow from "../components/buttons/button-arrow";
import Regular from "../components/typography/regular";
import Feedbacks from "../components/Feedbacks/Feedbacks";
import {Link} from "react-router-dom";
import Service from "../components/Service/Service";
import online_consultations from "../images/services/online-consultations.png"
import private_ from "../images/services/private.png"
import escort from "../images/services/escort.png"
import coaching from "../images/services/coaching.png"
import FormModal from "../components/Form/FormModal";
import {CountryContext} from "../contexts/countryContext";

const Home = () => {
    const [modal, setModal] = React.useState(false);

    const openModal = () => {
        setModal(true);
    }

    const closeModal = () => {
        setModal(false);
    }
    return (
        <div className="home">
            {modal && <FormModal closeModal={closeModal} />}
            <Introduction image={mariya_1}>
                <div>
                    <Headline1>Ваш Психолог</Headline1>
                    <Subtitle>Меня зовут Мария Мазур. Рада, что Вы зашли на мой сайт. Я практикующий психолог, коуч. За плечами имею большой опыт практики консультирования. Постоянно повышаю свою квалификацию.</Subtitle>
                    <Subtitle>На моих консультациях Вы получите поддержку и понимание, комфорт в общении и возможность говорить на любые темы. В своей работе я использую самые эффективные современные техники.</Subtitle>
                    <Button onClick={() => window.open('https://t.me/MaryiaMazur', '_self')}>Записаться</Button>
                </div>
                <div className="introduction__icons">
                    <Icons />
                </div>
            </Introduction>
            <section className="home__goal">
                <Headline2>Цель психологической помощи — исправить текущую проблемную ситуацию и предотвратить её повторения</Headline2>
            </section>
            <section className="home__requests">
                <Introduction right image={mariya_2}>
                    <div>
                        <Headline3>С какими запросами я работаю:</Headline3>
                        <Subtitle sb>Отношения в паре</Subtitle>
                        <Subtitle>Отсутствие взаимопонимания, тяжелое расставание, критика и неудовлетворенность, конфликты, обиды, измена партнера, созависимость</Subtitle>
                        <Subtitle sb>Работа с эмоциями</Subtitle>
                        <Subtitle>Отчаяние и растерянность, панические атаки, тревога, бессонница, горе и переживание утраты, обида, в том числе хронические обиды и зависть, страхи и фобии, стресс, перенапряжение, эмоциональное выгорание</Subtitle>
                        <Subtitle sb>Психологическое сопровождение</Subtitle>
                        <Subtitle>В трудных жизненных ситуациях и чрезвычайных ситуациях, опасных для жизни и здоровья человека</Subtitle>
                        <Subtitle sb>Семья</Subtitle>
                        <Subtitle>Супружеские отношения, неудовлетворенность браком, семейные конфликты, кризисы в отношениях, работа с разводящимися парами, отношения с родителями во взрослом возрасте (обиды, взаимное непонимание, противостояние, ссоры)</Subtitle>
                        <Subtitle sb>Возрастные кризисы</Subtitle>
                        <Subtitle>Пройти переоценку жизненного опыта безопасно и с выгодой для себя. Построить новую картину мира</Subtitle>
                        <Subtitle sb>Сексуальные проблемы в отношениях</Subtitle>
                        <Subtitle sb>Послеродовая депрессия</Subtitle>
                    </div>
                </Introduction>
            </section>
            <section className="home-width home__questionnaire">
                <Headline2 style={{width: '50%'}}>Страшно принять решение и выбрать психолога?</Headline2>
                <div className="questionnaire__text">
                    <div>
                        <Subtitle sb>Это нормально</Subtitle>
                        <Subtitle>Каждый человек хочет, чтобы и время, и деньги, инвестированные в терапию, принесли отдачу.</Subtitle>
                    </div>
                    <div>
                        <Subtitle>80% моих клиентов, получили именно то, что искали, и остались со мной до успешного окончания терапии.</Subtitle>
                        <Subtitle>Для вашей ситуации я смогу подобрать наиболее эффективные методики и формат терапии.</Subtitle>
                    </div>
                </div>
                <div className="questionnaire__open-form">
                    <Subtitle sb>Первая консультация продолжительностью 30 минут ‒ бесплатно</Subtitle>
                    <div><Button onClick={openModal}>Заполнить анкету</Button></div>
                </div>
            </section>
            <section className="home__images">
                <img src={image_1}/>
                <img src={image_2}/>
            </section>
            <section className="home__feedbacks">
                <Headline2>Отзывы</Headline2>
                <div className="feedbacks__header">
                    <Subtitle>Публикуются с согласия клиентов</Subtitle>
                    <Link onClick={() => window.scrollTo(0, 0)} to='/feedbacks'>
                        <ButtonArrow transparent><Regular sb>Больше отзывов</Regular></ButtonArrow>
                    </Link>
                </div>
                <Feedbacks />
            </section>
            <section className="home-width home__format">
                <Headline2>Формат оказания психологической помощи</Headline2>
                <Link onClick={() => window.scrollTo(0, 0)} to='/services'>
                    <ButtonArrow transparent><Regular sb>Смотреть все услуги</Regular></ButtonArrow>
                </Link>
            </section>
            <section className="home-width home__services">
                <Service
                    path_left="/services/online_consultations"
                    path_right="/services/private"
                    image_left={online_consultations}
                    image_right={private_}
                    serviceName_left="Онлайн-консультации"
                    serviceName_right="Личный прием психолога"
                />
                <Service
                    path_left="/services/escort"
                    path_right="/services/coaching"
                    image_left={escort}
                    image_right={coaching}
                    serviceName_left="Психологическое сопровождение"
                    serviceName_right="Личный коучинг"
                />
            </section>
        </div>
    );
};

export default Home;
