import React from 'react';
import ReactDOM from "react-dom";
import '../../style/form.scss'
import FormWrapper from "./FormWrapper";

const FormModal = ({closeModal}) => {
    return ReactDOM.createPortal(<FormWrapper closeModal={closeModal} />, document.getElementById('modal-form'))
};

export default FormModal;
