import Button from "./button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ButtonArrow = ({left, transparent, children, plain}) => {
    return (
        <Button transparent={transparent} plain={plain}>
            {left ? (
                <>
                    <FontAwesomeIcon style={{paddingRight: '20px'}} icon="fa-solid fa-angle-left" />
                    {children}
                </>
                )
                : (
                <>
                    {children}
                    <FontAwesomeIcon style={{paddingLeft: '20px'}} icon="fa-solid fa-angle-right" />
                </>
            )}
        </Button>
    )
}

export default ButtonArrow;
