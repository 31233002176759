import React from "react";
import { Link } from "react-router-dom";
import ButtonArrow from "../../components/buttons/button-arrow";
import Headline1 from "../../components/typography/headline1";
import Headline3 from "../../components/typography/headline3";
import Subtitle from "../../components/typography/subtitle";
import Regular from "../../components/typography/regular";
import supportImg from "../../images/servicePages/support.png";
import Button from "../../components/buttons/button";
import { CountryContext } from "../../contexts/countryContext";

const Support = () => {
  const { support, COUNTRY } = React.useContext(CountryContext);
  return (
    <div className="support">
      <section className="service-width service__header">
        <Link onClick={() => window.scrollTo(0, 0)} to="/services">
          <ButtonArrow left transparent>
            Вернуться к услугам
          </ButtonArrow>
        </Link>
        <Headline1>Поддерживающая консультация online/offline</Headline1>
        <div className="service__price">
          <Headline3>{COUNTRY === "Belarus" ? `Стоимость ${support}.` : `Недоступно в вашем регионе.`}</Headline3>
          <Subtitle>
            Продолжительность: <b>50 минут</b>
          </Subtitle>
        </div>
      </section>
      <section className="service-width service__main">
        <div className="service__main-left">
          <Regular>
            Поддерживающая консультация помогает, удерживать мотивацию,
            оттачивать мастерство в обучении при прохождении программ.Основной
            поддерживающий консультации является помощь в разъяснение и
            направлении. Поддерживающая консультация, выполняет много важных
            функций в процессе, например, таких как – помощь при прохождении
            заданий в трудный для психики момент, улучшение эмоционального
            состояния, сохранение чувства уверенности в себе и др.
          </Regular>
          <Regular style={{ marginTop: "5%" }}>
            Поддерживающая консультация — это дополнительная услуга к обучающим
            программам:
          </Regular>
          <Regular>- Программа "Радикальное прощение 21 день"</Regular>
          <Regular>- Программа "Радикальное принятия себя"</Regular>
        </div>
        <div className="service__main-right">
          <img width={"100%"} src={supportImg} />
          <Button
            onClick={() => window.open("https://t.me/MaryiaMazur", "_self")}
          >
            Записаться
          </Button>
        </div>
      </section>
    </div>
  );
};

export default Support;
