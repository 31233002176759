import React from 'react';
import '../../style/header.scss'
import Logo from "../Logo/Logo";
import Menu from "../Menu/Menu";
import Burger from "../mobile/HeaderMobile/Burger";

const Header = () => {
    return (
        <header className="header">
            <Logo />
            <Menu />
        </header>
    );
};

export default Header;
