import React from 'react';
import "./style/base.scss";
import {useCountry} from "./utils/useCountry";
import Desktop from "./entries/Desktop";
import Mobile from "./entries/Mobile";

const App = () => {
   const country = useCountry();
   const [width, setWidth] = React.useState(window.innerWidth);
    
   React.useEffect(() => {
       const handleResize = () => setWidth(window.innerWidth);

       window.addEventListener('resize', handleResize)

       return () => {
           window.removeEventListener('resize', handleResize)
       };

   }, [width]);

    return width > 1300 ? <Desktop  country={country} /> : <Mobile country={country} />
};

export default App;

