import React from 'react';
import Headline1 from "../../components/typography/headline1";
import '../../style/pages/services.scss';
import Headline3 from "../../components/typography/headline3";
import online_consultations from "../../images/services/online-consultations.png";
import private_ from "../../images/services/private.png";
import Service from "../../components/Service/Service";
import destructive from "../../images/services/desctructive-emotions.png"
import family from "../../images/services/family.png"
import support from "../../images/services/support.png"
import satori from "../../images/services/satori.png"
import yourself from "../../images/services/yourself.png"
import money from "../../images/services/money.png"
import forgiveness from "../../images/services/forgiveness.png"
import weight from "../../images/services/weight.png"
import sorrow from "../../images/services/sorrow.png"
import Button from "../../components/buttons/button";
import {Link, Outlet} from "react-router-dom";
import ServiceMobileCard from "../../components/Service/ServiceMobileCard";

const Services = () => {
    return (
        <>
            <div className="services">
                <section className="services-width services__services-cards mobile-width">
                    <Headline1 style={{marginBottom: "5%"}}>Услуги</Headline1>
                    <Headline3>Консультации</Headline3>
                    <ServiceMobileCard
                        path="/services/individual"
                        image={private_}
                        serviceName="Индивидуальная консультация"
                    />
                    <ServiceMobileCard
                        path="/services/destructive"
                        image={destructive}
                        serviceName="Индивидуальная консультация с проживанием деструктивных эмоций"
                    />
                    <ServiceMobileCard
                        path="/services/support"
                        image={support}
                        serviceName="Поддерживающая консультация"
                    />
                    <ServiceMobileCard
                        path="/services/family"
                        image={family}
                        serviceName="Семейные консультации"
                    />
                    <Headline3 style={{margin: "10% 0"}}>Онлайн-программы</Headline3>
                    <ServiceMobileCard
                        path="/services/forgiveness"
                        image={forgiveness}
                        serviceName="Радикальное прощение 21 день"
                    />
                    <ServiceMobileCard
                        path="/services/weight"
                        image={weight}
                        serviceName="Онлайн-программа «Вес»"
                    />
                    <ServiceMobileCard
                        path="/services/money"
                        image={money}
                        serviceName="Радикальные деньги"
                    />
                    <ServiceMobileCard
                        path="/services/yourself"
                        image={yourself}
                        serviceName="Радикальное принятие себя"
                    />
                    <ServiceMobileCard
                        path="/services/satori"
                        image={satori}
                        serviceName="«Сатори» (Осознание)"
                    />
                    <ServiceMobileCard
                        path='/services/sorrow'
                        image={sorrow}
                        serviceName='Скорбь'
                    />
                </section>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <a href="https://mariamazur.ru/oferta.rar" download="oferta.rar"><Button>Договор Оферты</Button></a>
                    <Link to="/contacts#payment"><Button>Способы оплаты</Button></Link>
                </div>
            </div>
            <Outlet />
        </>
    );
};

export default Services;
