import React from 'react';
import Headline3 from "../typography/headline3";
import ButtonArrow from "../buttons/button-arrow";
import styled from 'styled-components';
import Subtitle from "../typography/subtitle";
import {Link} from "react-router-dom";
import {colors} from "../../style/_variables";

const ServiceMobileCard = ({ image, serviceName, path}) => {
    return (
        <StyledServiceMobileCard image={image}>
            <div className='service__card-wrapper'>
                <Headline3>{serviceName}</Headline3>
                {path ?
                    <Link onClick={() => window.scrollTo(0, 0)}  to={path}><ButtonArrow transparent plain><Subtitle>Подробнее</Subtitle></ButtonArrow></Link>
                    : <ButtonArrow transparent plain><Subtitle>Подробнее</Subtitle></ButtonArrow>}
            </div>
        </StyledServiceMobileCard>
    );
};


const StyledServiceMobileCard = styled.div`
    background: url(${({image}) => image }) center no-repeat;
  width: 95%;
  background-size: cover;
  margin: 5% auto;
  height: 350px;
  
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 4px;
  
  & h1 {
    margin: 0;
    color: ${colors.white};
  }
  & button {
    padding-left: 0;
  }
  
`
export default ServiceMobileCard;
