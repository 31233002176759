import React from "react";
import Regular from "../../components/typography/regular";
import { colors } from "../../style/_variables";
import services from "../../utils/services";
import Description from "../../components/typography/description";

const PaymentService = ({ name, selected, setSelected, service, setSelectedService, selectedService }) => {
    return (
        <div className="payment-service">
            <Regular
                style={{ fontSize: selected === name ? "22px" : "", cursor: "pointer" }}
                color={colors.blue}
                sb={selected === name}
                onClick={() => {
                    selectedService?.orderNumber && setSelectedService(null);
                    setSelected(name);
                    !services[service].type &&
                        setSelectedService({
                            orderNumber: services[service].orderNumber,
                            sum: services[service].sum,
                        });
                }}
            >
                {name}
            </Regular>
            {selected === name ? (
                <div>
                    {Object.keys(services[service])
                        .filter((elem) => {
                            return elem !== "name" && elem !== "type";
                        })
                        .map((key, index) => {
                            return (
                                <Description
                                    style={{ cursor: "pointer", paddingLeft: "20px" }}
                                    key={index}
                                    onClick={() => {
                                        setSelectedService({
                                            orderNumber: services[service][key].orderNumber,
                                            sum: services[service][key].sum,
                                        });
                                    }}
                                >
                                    {services[service][key].orderNumber === selectedService?.orderNumber ? (
                                        <Regular style={{ fontWeight: "700" }}>{services[service][key].name}</Regular>
                                    ) : (
                                        services[service][key].name
                                    )}
                                </Description>
                            );
                        })}
                </div>
            ) : (
                ""
            )}
        </div>
    );
};

export default PaymentService;
