import React from "react";
import { Link } from "react-router-dom";
import ButtonArrow from "../../../components/buttons/button-arrow";
import Headline1 from "../../../components/typography/headline1";
import Headline3 from "../../../components/typography/headline3";
import Regular from "../../../components/typography/regular";
import Button from "../../../components/buttons/button";
import { CountryContext } from "../../../contexts/countryContext";

const Forgiveness = () => {
  const { forgiveness, COUNTRY } = React.useContext(CountryContext);

  return (
    <div className="forgiveness">
      <Link onClick={() => window.scrollTo(0, 0)} to="/services">
        <ButtonArrow left transparent>
          Вернуться к услугам
        </ButtonArrow>
      </Link>
      <section className="mobile-width service__header">
        <Headline1>Радикальное прощение 21 день</Headline1>
        <Regular>
          Цель программы направлена на избавление от угнетающих обид. Помогает
          простить и отпустить сложное прошлое, а также взглянуть на мир новыми
          глазами.
        </Regular>
        <Regular>
          Этот способ помог многим моим клиентам простить своего обидчика,
          наладить свою жизнь.
        </Regular>
        <Regular>
          Радикальное Прощение — это не просто техника, которая помогает
          отпустить события прошлого, но также и дорога к созданию новой жизни,
          которая полна любви и гармонии.
        </Regular>

        <Regular>Работа в программе сопровождается личными встречами.</Regular>
        <Regular sb>Кому подходит данная программа?</Regular>
        <Regular>
          Онлайн-программа «Радикальное Прощение 21 день» подойдёт тем людям,
          которые устали от тяжёлого груза обид. Людям, которые хотят научиться
          прощать, а также хотят отпустить негативные воспоминания о прошлом.
        </Regular>
      </section>
      <section className="mobile-width">
        <Headline3>
          Курс «Pro» + 2 консультации / Стоимость {forgiveness?.pro}.
        </Headline3>
        <Regular>
          РАДИКАЛЬНОЕ ПРОЩЕНИЕ или метод ТИППИНГА — это эффективная техника
          избавления от неприятных переживаний прошлого и возвращения к
          радостной и счастливой жизни. Продолжительность курса Радикальное
          Прощение «Pro» ‒ от 21 до 31 дней.
        </Regular>

        <Regular sb>В курс входит:</Regular>
        <Regular>• Работа в закрытой онлайн группе, ВК</Regular>
        <Regular>
          • Две бесплатные, индивидуальные консультации по 50 минут
        </Regular>
        {COUNTRY === "Belarus" && <Regular>• Возможность брать поддерживающие консультации.</Regular>}
        <Regular>
          • Сопровождение, обратная связь на протяжении 31 дня со старта
          программы.
        </Regular>
        <Regular>• Диагностика рисунков, мандала.</Regular>
        <Regular>• Релаксация с помощью погружения в медитацию.</Regular>
        <Regular>• 7 модулей, каждый включает 3 упражнения.</Regular>

        <Regular sb>Модуль 1. Подготовка</Regular>
        <Regular>
          В этом модуле вы узнаёте основные положения Радикального прощения. Вы
          вспоминаете все обиды, которые вам принесли папа и мама. А также
          выбираете, кого будете прощать в первую очередь: папу или маму.
        </Regular>

        <Regular sb>Модуль 2. Погружение</Regular>
        <Regular>
          В этом модуле вы высказываете свои претензии и переживаете свои
          чувства. Вы вспоминаете наиболее яркие ситуации, которые вызвали вашу
          обиду, и заново испытываете те чувства, которые возникают при
          припоминании обиды.
        </Regular>

        <Regular sb>Модуль 3. Освобождение</Regular>
        <Regular>
          В этом модуле вы с разных сторон посмотрите на ситуации, которые
          вызвали обиду. Вы осознаете, что 90% той обиды, которая есть, это ваши
          домысли и предположения, а также завышенные требования к себе и людям.
        </Regular>

        <Regular sb>Модуль 4. Исцеление</Regular>
        <Regular>
          Вы смотрите на всю эту ситуацию с духовной точки зрения. Вы начинаете
          понимать и видеть совершенство ситуации в том, как она есть. Обида и
          боль уходят, вас наполняет огромный поток энергии и любви. Вы
          исцелены.
        </Regular>

        <Regular sb>Модуль 5. Принятие</Regular>
        <Regular>В этом модуле вы:</Regular>
        <Regular>* сможете обрести понимание границ своих проблем;</Regular>
        <Regular>
          * поймёте, где заканчивается ваше поле деятельности и ответственности,
          и где начинается сфера ответственности других людей (родных и близкий,
          друзей и знакомых);
        </Regular>
        <Regular>* увидите жизнь сквозь новую раму, обрамление;</Regular>
        <Regular>
          * расширите границы и приобретёте новый уникальный опыт.
        </Regular>

        <Regular sb>Модуль 6. Навыки</Regular>
        <Regular>
          В этом модуле приобретёте навыки: навык релаксации, навык эффективного
          эмоционального отреагирования, навык осознания и проговаривания своих
          чувств, навык эффективной коммуникации.
        </Regular>

        <Regular sb>Модуль 7. Личностный рост</Regular>
        <Regular>
          Это заключительный модуль, где вы сможете осознать глубину, получите
          знания о своих сильных сторонах и понимание слабых, отыщите ресурсы и
          опоры, наметите путь в радость.
        </Regular>
        <Regular>• Заключение и подведение итогов.</Regular>
        <Regular>• Анализ проделанной работы, экспертная оценка.</Regular>
        {COUNTRY === "Belarus" && <><Headline3>Услуга «Раздели оплату» (200 бел. руб.)</Headline3>
        <Regular>
          Данная услуга предоставляет возможность оплатить онлайн-программу в 2
          платежа. Первый взнос необходимо внести на момент старта программы, а
          вторая часть после окончания курса.
        </Regular>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={() => window.open("https://t.me/MaryiaMazur", "_self")}
          >
            Записаться
          </Button>
        </div></>}
        <Headline3>
          Курс «Vip» — индивидуальное сопровождение + 2 бесплатные консультации
          Стоимость {forgiveness?.vip}.
        </Headline3>
        <Regular>
          РАДИКАЛЬНОЕ ПРОЩЕНИЕ или метод ТИППИНГА — это эффективная техника
          избавления от неприятных переживаний прошлого и возвращения к
          радостной и счастливой жизни. Продолжительность курса Радикальное
          Прощение «Vip» ‒ до 60 дней.
        </Regular>

        <Regular sb>В курс входит:</Regular>
        <Regular>
          • Работа в закрытой онлайн группе (в любом из мессенджеров Viber,
          WhatsApp, Telegram)
        </Regular>
        <Regular>• Индивидуальное сопровождение.</Regular>
        <Regular>
          • Две бесплатные, индивидуальные консультации по 50 минут
        </Regular>
        {COUNTRY === "Belarus" && <Regular>• Возможность брать поддерживающие консультации.</Regular>}
        <Regular>
          • Сопровождение, обратная связь на протяжении 31 дня со старта
          программы.
        </Regular>
        <Regular>• Диагностика рисунков, мандала.</Regular>
        <Regular>• Релаксация с помощью погружения в медитацию.</Regular>
        <Regular>• 7 модулей, каждый включает 3 упражнения.</Regular>

        <Regular sb>Модуль 1. Подготовка</Regular>
        <Regular>
          В этом модуле вы узнаёте основные положения Радикального прощения. Вы
          вспоминаете все обиды, которые вам принесли папа и мама. А также
          выбираете, кого будете прощать в первую очередь: папу или маму.
        </Regular>

        <Regular sb>Модуль 2. Погружение</Regular>
        <Regular>
          В этом модуле вы высказываете свои претензии и переживаете свои
          чувства. Вы вспоминаете наиболее яркие ситуации, которые вызвали вашу
          обиду, и заново испытываете те чувства, которые возникают при
          припоминании обиды.
        </Regular>

        <Regular sb>Модуль 3. Освобождение</Regular>
        <Regular>
          В этом модуле вы с разных сторон посмотрите на ситуации, которые
          вызвали обиду. Вы осознаете, что 90% той обиды, которая есть, это ваши
          домысли и предположения, а также завышенные требования к себе и людям.
        </Regular>

        <Regular sb>Модуль 4. Исцеление</Regular>
        <Regular>
          Вы смотрите на всю эту ситуацию с духовной точки зрения. Вы начинаете
          понимать и видеть совершенство ситуации в том, как она есть. Обида и
          боль уходят, вас наполняет огромный поток энергии и любви. Вы
          исцелены.
        </Regular>

        <Regular sb>Модуль 5. Принятие</Regular>
        <Regular>В этом модуле вы:</Regular>
        <Regular>* сможете обрести понимание границ своих проблем;</Regular>
        <Regular>
          * поймёте, где заканчивается ваше поле деятельности и ответственности,
          и где начинается сфера ответственности других людей (родных и близкий,
          друзей и знакомых);
        </Regular>
        <Regular>* увидите жизнь сквозь новую раму, обрамление;</Regular>
        <Regular>
          * расширите границы и приобретёте новый уникальный опыт.
        </Regular>

        <Regular sb>Модуль 6. Навыки</Regular>
        <Regular>
          В этом модуле приобретёте навыки: навык релаксации, навык эффективного
          эмоционального отреагирования, навык осознания и проговаривания своих
          чувств, навык эффективной коммуникации.
        </Regular>

        <Regular sb>Модуль 7. Личностный рост</Regular>
        <Regular>
          Это заключительный модуль, где вы сможете осознать глубину, получите
          знания о своих сильных сторонах и понимание слабых, отыщите ресурсы и
          опоры, наметите путь в радость.
        </Regular>
        <Regular>• Заключение и подведение итогов.</Regular>
        <Regular>• Анализ проделанной работы, экспертная оценка.</Regular>
        <Regular>
          В подарок — Программа Радикального прощения партнёра. Программа
          Радикального прощения партнёра — это программа исцеления отношений с
          партнёрами. Под словом «партнёры» Колин Типпинг подразумевает
          супругов, родственников, бизнес-партнёров, сожителей, близких друзей и
          подруг. Это может быть ваш начальник, подчинённый, или любой человек,
          который причинил вам боль или навредил. По своему воздействию эта
          программа повторяет мощь и силу Программы Радикального прощения
          родителей. Восстанавливается энергетика, уходят блоки и зажимы,
          которые отравляли жизнь. Глубокие осознания и серия энергетических
          сдвигов полностью растворяют обиды и претензии к близким людям, и
          позволяют вывести отношения с ними на совсем другой уровень.
        </Regular>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={() => window.open("https://t.me/MaryiaMazur", "_self")}
          >
            Записаться
          </Button>
        </div>
      </section>
    </div>
  );
};

export default Forgiveness;
