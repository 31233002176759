import React from 'react';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Header from "../components/Header/Header";
import Home from "../pages/Home";
import About from "../pages/About";
import Contacts from "../pages/Contacts";
import FeedbacksPage from "../pages/Feedbacks";
import Services from "../pages/Services";
import Individual from "../pages/services/Individual";
import Support from "../pages/services/Support";
import Family from "../pages/services/Family";
import Destructive from "../pages/services/Destructive";
import Forgiveness from "../pages/services/Forgiveness";
import Weight from "../pages/services/Weight";
import Money from "../pages/services/Money";
import Yourself from "../pages/services/Yourself";
import Satori from "../pages/services/Satori";
import Sorrow from "../pages/services/Sorrow";
import OnlineConsultations from "../pages/services/OnlineConsultations";
import Private from "../pages/services/Private";
import Escort from "../pages/services/Escort";
import Coaching from "../pages/services/Coaching";
import Footer from "../components/Footer/Footer";
import {CountryContext} from "../contexts/countryContext";
import Articles from "../pages/Articles";
import Payment from "../pages/Payment/Payment";
import LoveNNarcissism from 'pages/articles/LoveAndNarcissism';
import PsychoDream from 'pages/articles/PsychoDream';

const Desktop = ({country}) => {
    return (
        <CountryContext.Provider value={country}>
            <BrowserRouter>
                <Header />
                <Routes>
                    <Route path="/" element={<Navigate to="/home" />} />
                    <Route path="/home" element={<Home />}/>
                    <Route path="/about" element={<About />}/>
                    <Route path="/contacts" element={<Contacts />}/>
                    <Route path="/feedbacks" element={<FeedbacksPage />}/>
                    <Route path="/services">
                        <Route index element={<Services />} />
                        <Route path="individual" element={<Individual />} />
                        {<Route path="support" element={<Support />} />}
                        <Route path="family" element={<Family />} />
                        <Route path="destructive" element={<Destructive />} />
                        <Route path="forgiveness" element={<Forgiveness />} />
                        <Route path="weight" element={<Weight />} />
                        <Route path="money" element={<Money />} />
                        <Route path="yourself" element={<Yourself />} />
                        <Route path="satori" element={<Satori />} />
                        <Route path="sorrow" element={<Sorrow />} />
                        <Route path="online_consultations" element={<OnlineConsultations />} />
                        <Route path="private" element={<Private />} />
                        <Route path="escort" element={<Escort />} />
                        <Route path="coaching" element={<Coaching />} />
                    </Route>
                    <Route path="/articles">
                        <Route index element={<Articles />} />
                        <Route path="love-and-narcissism" element={<LoveNNarcissism />} />
                        <Route path="psycho-dream" element={<PsychoDream />} />
                    </Route>
                    <Route path='/payment' element={<Payment />} />
                </Routes>
                <Footer />
            </BrowserRouter>
        </CountryContext.Provider>
    );
};

export default Desktop;
