import styled from "styled-components";
import {colors} from "../../style/_variables";

const Description = styled.h1.attrs(props => ({
    className: props.sb ? 'regular-sb' : 'regular',
}))`
  color: ${({isForm, color='black'}) => isForm ? () => {
    return colors.gray
  } : color}
`
export default Description;
