import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {colors} from "../../style/_variables";

const Icons = ({white, modal}) => {

    return white ? <div className="icons" >
        <a href="https://vk.com/mariamazur_grodno" target="_blank"><FontAwesomeIcon style={{color: colors.white}} icon="fa-brands fa-vk" /></a>
        <a href="http://www.instagram.com/maryiamazur_" target="_blank"><FontAwesomeIcon style={{color: colors.white}} icon="fa-brands fa-instagram" /></a>
        <a href="https://us04web.zoom.us/j/8251078946?pwd=ZXphRGFZdzgwbjBxbmlIcnFsUzVzQT09" target="_blank"><FontAwesomeIcon style={{color: colors.white}} icon="fa-solid fa-video" /></a>
        <a href="https://t.me/psy_mazur" target="_blank"><FontAwesomeIcon style={{color: colors.white}} icon="fa-brands fa-telegram" /></a>
    </div> : <div className={`icons ${modal ? 'icons-modal': ''}`}>
        <a href="https://vk.com/mariamazur_grodno" target="_blank"><FontAwesomeIcon icon="fa-brands fa-vk" /></a>
        <a href="http://www.instagram.com/maryiamazur_" target="_blank"><FontAwesomeIcon icon="fa-brands fa-instagram" /></a>
        <a href="https://us04web.zoom.us/j/8251078946?pwd=ZXphRGFZdzgwbjBxbmlIcnFsUzVzQT09" target="_blank"><FontAwesomeIcon icon="fa-solid fa-video" /></a>
        <a href="https://t.me/psy_mazur" target="_blank"><FontAwesomeIcon icon="fa-brands fa-telegram" /></a>
    </div>
};

export default Icons;

