import React from "react";
import { Link } from "react-router-dom";
import ButtonArrow from "../../components/buttons/button-arrow";
import Headline1 from "../../components/typography/headline1";
import Headline3 from "../../components/typography/headline3";
import Subtitle from "../../components/typography/subtitle";
import Regular from "../../components/typography/regular";
import familyImg from "../../images/servicePages/family.png";
import Button from "../../components/buttons/button";
import { CountryContext } from "../../contexts/countryContext";

const Family = () => {
  const { family } = React.useContext(CountryContext);

  return (
    <div className="family">
      <section className="service-width service__header">
        <Link onClick={() => window.scrollTo(0, 0)} to="/services">
          <ButtonArrow left transparent>
            Вернуться к услугам
          </ButtonArrow>
        </Link>
        <Headline1>Семейные консультации online/offline</Headline1>
        <div className="service__price">
          <Headline3>Стоимость {family}.</Headline3>
          <Subtitle>
            Продолжительность: <b>1ч 20 минут</b>
          </Subtitle>
        </div>
      </section>
      <section className="service-width service__main">
        <div className="service__main-left">
          <Regular>
            Семейные консультации — это консультации по детско-родительским или
            супружеским отношениям.Всё общение происходит в форме диалога, т.к.
            всегда очень важна обратная связь.
          </Regular>
          <Regular>
            Существенный нюанс работы: вас слышу не только я, но и ваш партнёр.
            Благодаря этому можно достаточно быстро выяснить, где и какие есть
            причины взаимонепонимания, какие расхождения в том, что говорит
            один, и как это слышит другой. Много вопросов коммуникационных и
            даже ситуативных, улучшающих взаимопонимание, решается уже в
            процессе текущей консультации.
          </Regular>

          <Regular>
            — C супружеской парой или парой, находящейся в отношениях (в том
            числе, и однополой).
          </Regular>
          <Regular>
            — С детьми подростками в присутствии одного или двух родителей.
          </Regular>
          <Regular>
            — Взрослая дочь или сын в присутствии одного или двух родителей.
          </Regular>
        </div>
        <div className="service__main-right">
          <img width={"100%"} src={familyImg} />
          <Button
            onClick={() => window.open("https://t.me/MaryiaMazur", "_self")}
          >
            Записаться
          </Button>
        </div>
      </section>
    </div>
  );
};

export default Family;
