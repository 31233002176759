import React from 'react';
import Headline3 from "../typography/headline3";
import ButtonArrow from "../buttons/button-arrow";
import {colors} from "../../style/_variables";
import Subtitle from "../typography/subtitle";
import {Link} from "react-router-dom";

const ServiceCard = ({ image, serviceName, path}) => {
    return (
        <div className="service__card" >
                <img className="service__image" src={image} />
                <Headline3>{serviceName}</Headline3>
            {path ?
                <Link onClick={() => window.scrollTo(0, 0)} to={path}><ButtonArrow transparent plain><Subtitle>Подробнее</Subtitle></ButtonArrow></Link>
            : <ButtonArrow transparent plain><Subtitle>Подробнее</Subtitle></ButtonArrow>}
        </div>
    );
};

export default ServiceCard;
