import React from 'react';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Home from "../pages/mobile/Home";
import About from "../pages/mobile/About";
import Contacts from "../pages/mobile/Contacts";
import FeedbacksPage from "../pages/mobile/Feedbacks";
import Services from "../pages/mobile/Services";
import Individual from "../pages/mobile/services/Individual";
import Support from "../pages/mobile/services/Support";
import Family from "../pages/mobile/services/Family";
import Destructive from "../pages/mobile/services/Destructive";
import Forgiveness from "../pages/mobile/services/Forgiveness";
import Weight from "../pages/mobile/services/Weight";
import Money from "../pages/mobile/services/Money";
import Yourself from "../pages/mobile/services/Yourself";
import Satori from "../pages/mobile/services/Satori";
import Sorrow from "../pages/mobile/services/Sorrow";
import OnlineConsultations from "../pages/mobile/services/OnlineConsultations";
import Private from "../pages/mobile/services/Private";
import Escort from "../pages/mobile/services/Escort";
import Coaching from "../pages/mobile/services/Coaching";
import {CountryContext} from "../contexts/countryContext";
import HeaderMobile from "../components/mobile/HeaderMobile/HeaderMobile";
import FooterMobile from "../components/Footer/FooterMobile";
import ArticlesMobile from "../pages/mobile/ArticlesMobile";
import Payment from 'pages/Payment/Payment';
import LoveNNarcissism from 'pages/articles/LoveAndNarcissism';
import PsychoDream from 'pages/articles/PsychoDream';

const Mobile = ({country}) => {
    return (
        <CountryContext.Provider value={country}>
            <BrowserRouter>
                <HeaderMobile />
                <Routes>
                    <Route path="/" element={<Navigate to="/home" />} />
                    <Route path="/home" element={<Home />}/>
                    <Route path="/about" element={<About />}/>
                    <Route path="/contacts" element={<Contacts />}/>
                    <Route path="/feedbacks" element={<FeedbacksPage />}/>
                    <Route path="/services">
                        <Route index element={<Services />} />
                        <Route path="individual" element={<Individual />} />
                        <Route path="support" element={<Support />} />
                        <Route path="family" element={<Family />} />
                        <Route path="destructive" element={<Destructive />} />
                        <Route path="forgiveness" element={<Forgiveness />} />
                        <Route path="weight" element={<Weight />} />
                        <Route path="money" element={<Money />} />
                        <Route path="yourself" element={<Yourself />} />
                        <Route path="satori" element={<Satori />} />
                        <Route path="sorrow" element={<Sorrow />} />
                        <Route path="online_consultations" element={<OnlineConsultations />} />
                        <Route path="private" element={<Private />} />
                        <Route path="escort" element={<Escort />} />
                        <Route path="coaching" element={<Coaching />} />
                    </Route>
                    <Route path="/articles">
                        <Route index element={<ArticlesMobile />} />
                        <Route path="love-and-narcissism" element={<LoveNNarcissism />} />
                        <Route path="psycho-dream" element={<PsychoDream />} />
                    </Route>
                    <Route path='/payment' element={<Payment mobile={true} />} />
                </Routes>
                <FooterMobile />
            </BrowserRouter>
        </CountryContext.Provider>)
};

export default Mobile;
