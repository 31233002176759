import React from 'react';
import first from '../images/certificates/1.jpg';
import second from '../images/certificates/2.jpg';
import third from '../images/certificates/3.jpg';
import fourth from '../images/certificates/4.jpg';
import fifth from '../images/certificates/5.jpg';
import sixth from '../images/certificates/6.jpg';
import seventh from '../images/certificates/7.jpg';
import eighth from '../images/certificates/8.jpg';
import ninth from '../images/certificates/9.jpg';
import tenth from '../images/certificates/10.jpg';
import eleventh from "../images/certificates/11.jpg"
import twelfth from "../images/certificates/12.jpg"

const Certificates = () => {
    return (
        <div className="certificates__row" style={{marginBottom: '10%'}}>
            <img src={first} className="certificate__image"/>
            <img src={second} className="certificate__image"/>
            <img src={third} className="certificate__image"/>
            <img src={fourth} className="certificate__image"/>
            <img src={fifth} className="certificate__image"/>
            <img src={sixth} className="certificate__image"/>
            <img src={seventh} className="certificate__image"/>
            <img src={eighth} className="certificate__image"/>
            <img src={ninth} className="certificate__image"/>
            <img src={tenth} className="certificate__image"/>
            <img src={eleventh} className="certificate__image"/>
            <img src={twelfth} className="certificate__image"/>
        </div>
    );
};

export default Certificates;
