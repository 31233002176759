import React, {useEffect} from 'react';
import {Link, useLocation} from "react-router-dom";
import {colors} from "../../style/_variables";
import Regular from "../typography/regular";
import Icons from "../Icons/Icons";

const ModalMenuWrapper = ({setIsOpen}) => {
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        }
    })

    const location = useLocation();
    const matchLocation = (pathname) => {
        return location.pathname.includes(pathname) ? colors.blue : colors.black;
    }

    return (
        <div className='modal-menu'>
            <div className='modal-menu__wrapper'>
                <div className='modal-menu__navigation'>
                    <Link onClick={() => {setIsOpen(false); window.scrollTo(0, 0) }} to="/home"><Regular color={matchLocation("/home")}>Главная</Regular></Link>
                    <Link onClick={() => {setIsOpen(false); window.scrollTo(0, 0) }} to="/about"><Regular color={matchLocation("/about")}>Обо мне</Regular></Link>
                    <Link onClick={() => {setIsOpen(false); window.scrollTo(0, 0) }} to="/articles"><Regular color={matchLocation("/articles")}>Полезное</Regular></Link>
                    <Link onClick={() => {setIsOpen(false); window.scrollTo(0, 0) }} to="/services"><Regular color={matchLocation("/services")}>Услуги</Regular></Link>
                    <Link onClick={() => {setIsOpen(false); window.scrollTo(0, 0) }} to="/contacts"><Regular color={matchLocation("/contacts")}>Контакты</Regular></Link>
                    <Link onClick={() => {setIsOpen(false); window.scrollTo(0, 0) }} to="/feedbacks"><Regular color={matchLocation("/feedbacks")}>Отзывы</Regular></Link>
                </div>
                <div className='modal-menu__contacts'>
                    <Regular>+375 29 549-39-52<br/>+375 29 549-39-52<br/>psi_mariamazur@mail.ru</Regular>
                    <Icons modal={true}/>
                </div>
            </div>
        </div>
    );
};

export default ModalMenuWrapper;
