import React from "react";
import { Link } from "react-router-dom";
import ButtonArrow from "../../../components/buttons/button-arrow";
import Headline1 from "../../../components/typography/headline1";
import Headline3 from "../../../components/typography/headline3";
import Subtitle from "../../../components/typography/subtitle";
import Regular from "../../../components/typography/regular";
import supportImg from "../../../images/servicePages/support.png";
import Button from "../../../components/buttons/button";
import { CountryContext } from "../../../contexts/countryContext";
import Headline2 from "../../../components/typography/headline2";

const Support = () => {
  const { support } = React.useContext(CountryContext);
  return (
    <div className="support">
      <Link onClick={() => window.scrollTo(0, 0)} to="/services">
        <ButtonArrow left transparent>
          Вернуться к услугам
        </ButtonArrow>
      </Link>
      <section className="mobile-width service__header">
        <Headline2>Поддерживающая консультация online/offline</Headline2>
        <Headline3>Стоимость {support}.</Headline3>
        <Subtitle>
          Продолжительность: <b>50 минут</b>
        </Subtitle>
      </section>
      <img width={"100%"} src={supportImg} />
      <section className="mobile-width">
        <Regular>
          Поддерживающая консультация помогает, удерживать мотивацию, оттачивать
          мастерство в обучении при прохождении программ.Основной поддерживающий
          консультации является помощь в разъяснение и направлении.
          Поддерживающая консультация, выполняет много важных функций в
          процессе, например, таких как – помощь при прохождении заданий в
          трудный для психики момент, улучшение эмоционального состояния,
          сохранение чувства уверенности в себе и др.
        </Regular>
        <Regular style={{ marginTop: "5%" }}>
          Поддерживающая консультация — это дополнительная услуга к обучающим
          программам:
        </Regular>
        <Regular>- Программа "Радикальное прощение 21 день"</Regular>
        <Regular>- Программа "Радикальное принятия себя"</Regular>
      </section>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          onClick={() => window.open("https://t.me/MaryiaMazur", "_self")}
        >
          Записаться
        </Button>
      </div>
    </div>
  );
};

export default Support;
