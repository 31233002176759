import React, {useEffect} from 'react';
import {useFormik} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "../buttons/button";
import Regular from "../typography/regular";
import Headline3 from "../typography/headline3";
import Description from "../typography/description";
import {BeatLoader} from "react-spinners";
import {colors} from "../../style/_variables";

const FormWrapper = ({closeModal}) => {
    const [sending, setSending] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        }
    })

    const validate = (values, props /* only available when using withFormik */) => {

    };
    const close = (e)  => {
        e.target.className === 'form' && closeModal();
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '+375',
            age: '',
            intuitiveAge: '',
            education: '',
            work: '',
            healthScore: '',
            aboutFamilyAndMyself: '',
            aboutProblem: '',
            mainProblem: '',
            problemTime: '',
            expectation: '',
            parentsFamily: '',
            environment: '',
            maritalStatus: '',
            hobby: '',
            values: '',
            badHabits: '',
            psychologicalDeceases: '',
            psychoExperience: '',
            maternityLeave: '',
            questions: '',
            ifUnsuccessful: '',
            achievements3_6: '',
            somethingElse: ''
        },
        validate : values => {
            const errors = {};

            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                errors.email = 'Введенный адрес электронной почты неправильного формата';
            }

            if(values.phone.split('-').join().length <= 12) {
                errors.phone = 'Неправильный номер телефона';
            }

            for(let key in values) {
                if(key === 'maternityLeave' || key === 'somethingElse') continue;
                if(!values[key]) {

                    errors[key] = 'Обязательное поле';
                    if(Object.keys(errors).length !== 0) {
                        errors.all = 'Пожалуйста, заполните обязательные поля';
                    }
                }
            }
            // if all fields are not filled

            return errors;
        },
        onSubmit: async (values) => {
            setLoading(true)
            const formData = new FormData();
            for (let key in values) {

                formData.append(key, values[key]);
            }
            await fetch('https://mariamazur.ru/Send_Anketa.php', {
                method: 'POST',
                body: formData
            }).then(() => {
                setLoading(false)
                setSending(true);
            })
            await new Promise(resolve => setTimeout(resolve, 2000));
            closeModal();
        }
    })
    return (
        <div className="form"  onClick={e => close(e)} >
            <form onSubmit={formik.handleSubmit}>
                <label htmlFor="name"><Headline3>Ваше Имя: </Headline3></label>
                <label id='error' htmlFor="name">{formik.errors.name}</label>
                <label htmlFor="name"><Description isForm>Ваше имя, либо тот вариант, по которому вам будет комфортно обращаться </Description></label>
                <input
                    id="name"
                    name="name"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                />

                <label htmlFor="email"><Headline3>Ваша почта: </Headline3></label>
                <label id='error' htmlFor="email">{formik.errors.email}</label>
                <input
                    id="email"
                    name="email"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                />

                <label htmlFor="phone"><Headline3>Ваш номер телефона: </Headline3></label>
                <label id='error' htmlFor="phone">{formik.errors.phone}</label>
                <input
                    id="phone"
                    name="phone"
                    type='tel'
                    maxLength={13}
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                />

                <label htmlFor="age"><Headline3>Ваш возраст: </Headline3></label>
                <label id='error' htmlFor="age">{formik.errors.age}</label>
                <label htmlFor="age"><Description isForm>Возраст по паспорту</Description></label>
                <input
                    id="age"
                    name="age"
                    type='tel'
                    maxLength={2}
                    onChange={formik.handleChange}
                    value={formik.values.age}
                />

                <label htmlFor="intuitiveAge"><Headline3>Ваш интуитивный возраст: </Headline3></label>
                <label id='error' htmlFor="intuitiveAge">{formik.errors.intuitiveAge}</label>
                <label htmlFor="intuitiveAge"><Description isForm>На сколько себя ощущаете</Description></label>
                <input
                    id="intuitiveAge"
                    name="intuitiveAge"
                    type='tel'
                    maxLength={2}
                    onChange={formik.handleChange}
                    value={formik.values.intuitiveAge}
                />

                <label htmlFor="education"><Headline3>Ваше образование: </Headline3></label>
                <label id='error' htmlFor="education">{formik.errors.education}</label>
                <label htmlFor="education"><Description isForm>На данный момент, есть ли желание повысить его, либо изменить направление деятельности</Description></label>
                <input
                    id="education"
                    name="education"
                    type='text'
                    onChange={formik.handleChange}
                    value={formik.values.education}
                />

                <label htmlFor="work"><Headline3>Место работы: </Headline3></label>
                <label id='error' htmlFor="work">{formik.errors.work}</label>
                <label htmlFor="work"><Description isForm>Где, кем работаете, устраивает ли вас ваша деятельность</Description></label>
                <input
                    id="work"
                    name="work"
                    type='text'
                    onChange={formik.handleChange}
                    value={formik.values.work}
                />

                <label htmlFor="healthScore"><Headline3>Оценка здоровья: </Headline3></label>
                <label id='error' htmlFor="healthScore">{formik.errors.healthScore}</label>
                <label htmlFor="healthScore"><Description isForm>Оцените свое здоровье, как себя чувствуете. Если есть проблемы, наследственность, принимаете лекарства, напишите.</Description></label>
                <textarea
                    id="healthScore"
                    name="healthScore"
                    onChange={formik.handleChange}
                    value={formik.values.healthScore}
                />

                <label htmlFor="aboutFamilyAndMyself"><Headline3>Опишите себя и семью: </Headline3></label>
                <label id='error' htmlFor="aboutFamilyAndMyself">{formik.errors.aboutFamilyAndMyself}</label>
                <label htmlFor="aboutFamilyAndMyself"><Description isForm>Родители, сестры, братья, отношения в детстве и сейчас.</Description></label>

                <textarea
                    id="aboutFamilyAndMyself"
                    name="aboutFamilyAndMyself"
                    onChange={formik.handleChange}
                    value={formik.values.aboutFamilyAndMyself}
                />

                <label htmlFor="aboutProblem"><Headline3>Опишите вашу проблему: </Headline3></label>
                <label id='error' htmlFor="aboutProblem">{formik.errors.aboutProblem}</label>
                <label htmlFor="aboutProblem"><Description isForm>Опишите вашу проблему или ситуацию, которую вы хотите разрешить.</Description></label>

                <textarea
                    id="aboutProblem"
                    name="aboutProblem"
                    onChange={formik.handleChange}
                    value={formik.values.aboutProblem}
                />

                <label htmlFor="mainProblem"><Headline3>Главная причина: </Headline3></label>
                <label id='error' htmlFor="mainProblem">{formik.errors.mainProblem}</label>
                <label htmlFor="mainProblem"><Description isForm>Какая главная причина, из-за которой вы не можете решить эту проблему?</Description></label>

                <input
                    id="mainProblem"
                    name="mainProblem"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.mainProblem}
                />

                <label htmlFor="problemTime"><Headline3>Проблема: </Headline3></label>
                <label id='error' htmlFor="problemTime">{formik.errors.problemTime}</label>
                <label htmlFor="problemTime"><Description isForm>Как давно существует проблема?</Description></label>

                <input
                    id="problemTime"
                    name="problemTime"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.problemTime}
                />

                <label htmlFor="expectation"><Headline3>Что хотите получить в итоге: </Headline3></label>
                <label id='error' htmlFor="expectation">{formik.errors.expectation}</label>
                <label htmlFor="expectation"><Description isForm>Что хотите получить, как результат работы с психологом? (рекомендации, совет, поддержку или иное)</Description></label>

                <textarea
                    id="expectation"
                    name="expectation"
                    onChange={formik.handleChange}
                    value={formik.values.expectation}
                />

                <label htmlFor="parentsFamily"><Headline3>Ваша родительская семья : </Headline3></label>
                <label id='error' htmlFor="parentsFamily">{formik.errors.parentsFamily}</label>
                <label htmlFor="parentsFamily"><Description isForm>Ваша родительская семья, братья, сестры, отношения в детстве и сейчас (всё, что придет в голову, насколько сможете подробно)</Description></label>

                <textarea
                    id="parentsFamily"
                    name="parentsFamily"
                    onChange={formik.handleChange}
                    value={formik.values.parentsFamily}
                />

                <label htmlFor="environment"><Headline3>Окружение и отношения: </Headline3></label>
                <label id='error' htmlFor="environment">{formik.errors.environment}</label>
                <label htmlFor="environment"><Description isForm>Окружение и отношения: с противоположным полом, с друзьями</Description></label>

                <textarea
                    id="environment"
                    name="environment"
                    onChange={formik.handleChange}
                    value={formik.values.environment}
                />

                <label htmlFor="maritalStatus"><Headline3>Семейное положение, дети: </Headline3></label>
                <label id='error' htmlFor="maritalStatus">{formik.errors.maritalStatus}</label>
                <label htmlFor="maritalStatus"><Description isForm>Опишите свое семейное положение и про детей, если есть.</Description></label>

                <input
                    id="maritalStatus"
                    name="maritalStatus"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.maritalStatus}
                />

                <label htmlFor="hobby"><Headline3>Хобби: </Headline3></label>
                <label id='error' htmlFor="hobby">{formik.errors.hobby}</label>
                <label htmlFor="hobby"><Description isForm>Ваши увлечения (хобби) — с детства и сейчас.</Description></label>

                <textarea
                    id="hobby"
                    name="hobby"
                    onChange={formik.handleChange}
                    value={formik.values.hobby}
                />

                <label htmlFor="values"><Headline3>Ценности: </Headline3></label>
                <label id='error' htmlFor="values">{formik.errors.values}</label>
                <label htmlFor="values"><Description isForm>Ваши ценности: что для вас важно в жизни?</Description></label>

                <textarea
                    id="values"
                    name="values"
                    onChange={formik.handleChange}
                    value={formik.values.values}
                />

                <label htmlFor="badHabits"><Headline3>Вредные привычки: </Headline3></label>
                <label id='error' htmlFor="badHabits">{formik.errors.badHabits}</label>
                <label htmlFor="badHabits"><Description isForm>Употребляете ли Вы ПАВ (алкоголь, наркотики, сигареты, спайсы и пр.)?</Description></label>

                <input
                    id="badHabits"
                    name="badHabits"
                    onChange={formik.handleChange}
                    value={formik.values.badHabits}
                />

                <label htmlFor="psychologicalDeceases"><Headline3>Есть ли психические заболевания: </Headline3></label>
                <label id='error' htmlFor="psychologicalDeceases">{formik.errors.psychologicalDeceases}</label>
                <label htmlFor="psychologicalDeceases"><Description isForm>У Вас или у родственников, есть ли психические заболевания, если да — то какие, когда проявлялись и как?</Description></label>

                <textarea
                    id="psychologicalDeceases"
                    name="psychologicalDeceases"
                    onChange={formik.handleChange}
                    value={formik.values.psychologicalDeceases}
                />

                <label htmlFor="psychoExperience"><Headline3>Опыт работы с психологом: </Headline3></label>
                <label id='error' htmlFor="psychoExperience">{formik.errors.psychoExperience}</label>
                <label htmlFor="psychoExperience"><Description isForm>Был ли опыт работы с психологом раньше, какой именно, какие результаты получили, завершили ли процесс?</Description></label>

                <input
                    id="psychoExperience"
                    name="psychoExperience"
                    onChange={formik.handleChange}
                    value={formik.values.psychoExperience}
                />

                <label htmlFor="maternityLeave"><Headline3>Вы мама в декрете?: </Headline3></label>
                <label id='error' htmlFor="maternityLeave">{formik.errors.maternityLeave}</label>
                <label htmlFor="maternityLeave"><Description isForm>Если вы мама в декрете, то сообщите об этом здесь</Description></label>

                <input
                    id="maternityLeave"
                    name="maternityLeave"
                    onChange={formik.handleChange}
                    value={formik.values.maternityLeave}
                />

                <label htmlFor="questions"><Headline3>Вопросы для встречи: </Headline3></label>
                <label id='error' htmlFor="questions">{formik.errors.questions}</label>
                <label htmlFor="questions"><Description isForm>Напишите от 3 до 5 вопросов (можно и более) на которые вы хотите получить ответ на первой встрече.</Description></label>

                <input
                    id="questions"
                    name="questions"
                    onChange={formik.handleChange}
                    value={formik.values.questions}
                />

                <label htmlFor="ifUnsuccessful"><Headline3>Если проблема не решится: </Headline3></label>
                <label id='error' htmlFor="ifUnsuccessful">{formik.errors.ifUnsuccessful}</label>
                <label htmlFor="ifUnsuccessful"><Description isForm>Что произойдет в вашей жизни, если проблема не будет решена?</Description></label>

                <textarea
                    id="ifUnsuccessful"
                    name="ifUnsuccessful"
                    onChange={formik.handleChange}
                    value={formik.values.ifUnsuccessful}
                />

                <label htmlFor="achievements3_6"><Headline3>Ваши достижения через 3 - 6 месяцев: </Headline3></label>
                <label id='error' htmlFor="achievements3_6">{formik.errors.achievements3_6}</label>
                <label htmlFor="questions"><Description isForm>Чего бы Вы хотели достичь в вашей жизни через 3 - 6 месяцев?</Description></label>

                <textarea
                    id="achievements3_6"
                    name="achievements3_6"
                    onChange={formik.handleChange}
                    value={formik.values.achievements3_6}
                />

                <label htmlFor="somethingElse"><Headline3>Что-то еще важное: </Headline3></label>
                <label id='error' htmlFor="somethingElse">{formik.errors.somethingElse}</label>
                <label htmlFor="somethingElse"><Description isForm>Что-то еще важное (значимое), что Вы хотите сказать. Можете указать удобное для Вас время проведения предварительной консультации (время Минское).</Description></label>

                <input
                    id="somethingElse"
                    name="somethingElse"
                    onChange={formik.handleChange}
                    value={formik.values.somethingElse}
                />
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2%'}}>
                    <Regular style={{color: 'red'}}>{formik.errors?.all}</Regular>
                    {loading ?
                        <BeatLoader color={colors.blue}  />
                        : <Button disabled={sending} id='submit' type="submit">{sending ? 'Анкета отправлена!' :'Отправить'}</Button>
                    }
                    <button className='close-form' onClick={closeModal}><FontAwesomeIcon icon="fa-solid fa-xmark" /></button>
                </div>
            </form>

        </div>
    );
};

export default FormWrapper;
