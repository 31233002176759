import React from "react";
import { Link } from "react-router-dom";
import ButtonArrow from "../../components/buttons/button-arrow";
import Headline1 from "../../components/typography/headline1";
import Headline3 from "../../components/typography/headline3";
import Regular from "../../components/typography/regular";
import moneyImg from "../../images/servicePages/money.png";
import Button from "../../components/buttons/button";
import { CountryContext } from "../../contexts/countryContext";

const Money = () => {
  const { money } = React.useContext(CountryContext);

  return (
    <div className="money">
      <section className="service-width service__header">
        <Link onClick={() => window.scrollTo(0, 0)} to="/services">
          <ButtonArrow left transparent>
            Вернуться к услугам
          </ButtonArrow>
        </Link>
        <Headline1>Онлайн-программа «Радикальные деньги»</Headline1>
      </section>
      <section className="service-width service__main">
        <div className="service__main-left">
          <Regular>
            Программа поможет вам избавиться от негативных стратегий «нищеты»,
            блокирующих приток денег, а также запустить новые денежные
            программы, чтобы увеличить свой доход в 4 раза.
            <Regular>Для кого подходит программа?</Regular>
            Для тех, кто хочет изменить своё финансовое положение к лучшему, кто
            устал жить в «долг» Программа предусматривает групповой формат
            работы и индивидуальное сопровождение.
          </Regular>
          <Regular sb>Курс Pro:</Regular>
          <Regular>
            * Работа в закрытой онлайн группе, ВК или в других мессенджерах
            Viber, WhatsApp и др.
          </Regular>
          <Regular>
            * 2 бесплатные индивидуальные консультации по 50 мин.
          </Regular>
          <Regular>
            * Полное сопровождение, а также обратная связь на протяжении 90
            дней, начиная со старта программы.
          </Regular>
          <Regular>
            * Инструменты «Радикального прощения», которые предназначены для
            самопомощи.
          </Regular>
          <Regular>* Релаксация с помощью погружения в медитацию.</Regular>

          <Regular>
            * Осознать свои сегодняшние взаимоотношения с деньгами, выявить
            денежную биографию, ограничивающие убеждения, стереотипы мышления.
          </Regular>
          <Regular>Блок состоит из 7 онлайн модулей.</Regular>
          <Regular> — Финансовая биография за полгода.</Regular>
          <Regular>— Деньги в моем сознании, прошлое и настоящее.</Regular>
          <Regular>— Здоровая критика «где я сейчас»?</Regular>
          <Regular>— Независимая оценка «моё состояние в чистом виде»?</Regular>
          <Regular>— Работа с мотивацией «личность и деньги»</Regular>
          <Regular>— Из дневников прошлого «моя история: я и деньги»</Regular>
          <Regular>— Анализ, что значат для меня деньги?</Regular>
          <Regular>
            — Работа с подсознанием «моё отношение к людям с деньгами»
          </Regular>

          <Regular sb>Блок 2:</Regular>
          <Regular>
            * Демонтировать свою прежнюю историю о деньгах, признать, принять и
            отпустить всё, что было связано с деньгами в своей жизни, а также в
            жизни родителей и родственников.
          </Regular>
          <Regular>Состоит из 8 онлайн модулей.</Regular>
          <Regular>— Деньги и Я</Regular>
          <Regular>— Есть ли связь «социальное положение и деньги»?</Regular>
          <Regular>— К какой социальной группе я принадлежу</Regular>
          <Regular>— Деньги в жизни моей семьи</Regular>
          <Regular>— Деньги в жизни моих друзей</Regular>
          <Regular>
            — Гендерные факторы, влияющие на финансовое сознание
          </Regular>
          <Regular>— Стержневые убеждения касающееся денег</Regular>
          <Regular>— Моё представление о себе и деньгах</Regular>

          <Regular sb>Блок 3:</Regular>
          <Regular>
            * С помощью простых инструментов «Радикального прощения» внедрить в
            себя новые денежные программы, которые на автопилоте выведут на
            вершину успеха.
          </Regular>
          <Regular>Состоит из 5 онлайн модулей.</Regular>
          <Regular>
            — Мысли позитивно «аффирмации» на каждый день» или отказ от прежних
            негативных убеждений в отношении денег.
          </Regular>
          <Regular>
            — Инструменты Радикального Прощения — убираем блоки, психологические
            защиты «распознать — значит понять»
          </Regular>
          <Regular>
            — Медитация 13-шаговый процесс, избавления от ограничивающих
            убеждений в денежных вопросах
          </Regular>
          <Regular>— Работа с анкетой «устранения денежных проблем»</Regular>
          <Regular>— Трансформация убеждений</Regular>

          <Regular sb>Блок 4:</Regular>
          <Regular>
            * Финансовая грамотность и знания, позволяют, настроить своё
            финансовое мышление на Благополучие и Богатство, чтобы притягивать
            новые возможности. Состоит из 6 онлайн модулей.
          </Regular>
          <Regular>
            — Новое обрамление «что означают для меня деньги сегодня»?
          </Regular>
          <Regular>
            — Новое обрамление «как я отношусь к людям с деньгами сегодня»?
          </Regular>
          <Regular>
            — Новое обрамление «какую ценность имеют для меня деньги сегодня»?
          </Regular>
          <Regular>
            — Новое обрамление «для чего мне нужны деньги сегодня»?
          </Regular>
          <Regular>— Мои умения таланты и способности?</Regular>
          <Regular>— Новое обрамление «мои нынешние приоритеты»?</Regular>

          <Regular sb>Блок 5:</Regular>
          <Regular>
            * Намерение — это свеча, которую мы зажигаем, чтобы убрать темноту
            на пути к цели. Движение к цели — это конкретный алгоритм действий.
          </Regular>
          <Regular>
            Мечтать и планировать, представлять себя в будущем и делать
            конкретные шаги!
          </Regular>
          <Regular>Состоит из 4 онлайн модулей.</Regular>
          <Regular>— Мои финансовые цели</Regular>
          <Regular>— Финансовая установка на будущее</Regular>
          <Regular>— Как я намерен/а осуществлять своё видение</Regular>
          <Regular>— PowerShift или групповой метод силового сдвига</Regular>
          <Regular>
            Программа поможет вам избавиться от негативных стратегий «нищеты»,
            блокирующих приток денег, а также запустить новые денежные
            программы, чтобы увеличить свой доход в 4 раза.
          </Regular>

          <Regular sb>Для кого подходит программа?</Regular>
          <Regular>
            Для тех, кто хочет изменить своё финансовое положение к лучшему, кто
            устал жить в «долг» Программа предусматривает групповой формат
            работы и индивидуальное сопровождение.
          </Regular>

          <Regular sb>Что вы получите?</Regular>
          <Regular>
            Занимаясь по курсу, у вас появится возможность определить, что
            мешает приходу денег в Вашу жизнь. В процессе работы уберутся все
            блоки и негативные установки относительно денег.
          </Regular>
        </div>
        <div className="service__main-right">
          <img width={"100%"} src={moneyImg} />
        </div>
      </section>
      <section className="services-width service__buttons">
        <div>
          <Headline3>
            Пакет Pro - продолжительность 90 дней, стоимость {money?.pro}
          </Headline3>
          <Button
            onClick={() => window.open("https://t.me/MaryiaMazur", "_self")}
          >
            Записаться
          </Button>
        </div>
        <div>
          <Headline3>
            Пакет Vip - продолжительность 90 дней, полное индивидуальное
            сопровождение. Стоимость программы {money?.vip}.
          </Headline3>
          <Button
            onClick={() => window.open("https://t.me/MaryiaMazur", "_self")}
          >
            Записаться
          </Button>
        </div>
      </section>
    </div>
  );
};

export default Money;
