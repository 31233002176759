import React from 'react';
import {Link, useLocation} from "react-router-dom";
import Regular from "../typography/regular";
import {colors} from "../../style/_variables";

const Menu = () => {
    const location = useLocation();
    const matchLocation = (pathname) => {
        return location.pathname.includes(pathname) ? colors.blue : colors.black;
    }
    return (
        <menu className="menu">
            <ul className="menu__list">
                <li className="menu__item"><Link onClick={() => window.scrollTo(0, 0)} to="/home"><Regular color={matchLocation("/home")}>Главная</Regular></Link></li>
                <li className="menu__item"><Link onClick={() => window.scrollTo(0, 0)} to="/about"><Regular color={matchLocation("/about")}>Обо мне</Regular></Link></li>
                <li className="menu__item"><Link onClick={() => window.scrollTo(0, 0)} to="/articles"><Regular color={matchLocation("/articles")}>Полезное</Regular></Link></li>
                <li className="menu__item"><Link onClick={() => window.scrollTo(0, 0)} to="/services"><Regular color={matchLocation("/services")}>Услуги</Regular></Link></li>
                <li className="menu__item"><Link onClick={() => window.scrollTo(0, 0)} to="/contacts"><Regular color={matchLocation("/contacts")}>Контакты</Regular></Link></li>
                <li className="menu__item"><Link onClick={() => window.scrollTo(0, 0)} to="/feedbacks"><Regular color={matchLocation("/feedbacks")}>Отзывы</Regular></Link></li>
            </ul>
        </menu>
    );
};

export default Menu;
