import React from 'react';
import {Link} from "react-router-dom";
import ButtonArrow from "../../../components/buttons/button-arrow";
import Headline1 from "../../../components/typography/headline1";
import Regular from "../../../components/typography/regular";
import sorrowImg from "../../../images/servicePages/sorrow.png";
import Button from "../../../components/buttons/button";
import {CountryContext} from "../../../contexts/countryContext";
import Headline2 from "../../../components/typography/headline2";

const Sorrow = () => {
    const {sorrow, COUNTRY} = React.useContext(CountryContext);
    return (
        <div className="satori">
                <Link onClick={() => window.scrollTo(0, 0)} to="/services"><ButtonArrow left transparent>Вернуться к услугам</ButtonArrow></Link>
            <section className="mobile-width service__header">
                <Headline2>«СКОРБЬ»</Headline2>
            </section>
            <img width={"100%"} src={sorrowImg} />
            <section className="mobile-width">
                    <Regular>Скорбь — психическое состояние, обусловленное глубокими переживаниями утраты и сопровождающееся снижением интереса к внешнему миру, погруженностью в себя и поглощенностью мучительными воспоминаниями. Скорбь ввинчивается глубоко в сердце и никогда не покидает вас.</Regular>
                    <Regular sb>Для кого рассчитана программа?</Regular>
                    <Regular>Для тех, кто столкнулся с безутешным горем из-за смерти родного и близкого человека.</Regular>
                    <Regular>Когда человек теряет кого-то из близких, он проходит этапы:</Regular>
                    <Regular>— протестует против утраты, отрицает то, что близкого уже нет, не верит в случившееся;</Regular>
                    <Regular>— принимает факт утраты и признает её реальность;</Regular>
                    <Regular>- разрывает связь с ушедшим человеком, отделяется от идентификации с ним; учится жить самостоятельно, выстраивает эмоциональные связи и новые взаимоотношения, возможно (но не обязательно) развивает привязанность к другому человеку.</Regular>
                    <Regular>Программа поможет вам пройти путь примирения с утратой близкого человека.</Regular>
                    <Regular sb> Подробнее о программе:</Regular>
                    <Regular>Программа состоит блоков и модулей, всего 5 блоков, включающих 13 встреч:</Regular>
                    <Regular sb >1-й блок: Работа с отрицанием, переживанием шока.</Regular>
                    <Regular> На этом этапе, сложно говорить про какое-то осознание утраты. Обычно говорят «головой понимаю», что что-то случилось, но внутри пока ещё признания нет. На этом этапе важно не принимать никаких важных решений, а просто понять своё состояние.</Regular>
                    <Regular sb>2-й блок: Прохождение через гнев и агрессию.</Regular>
                    <Regular>Любая потеря приводит нас в ярость, даже если она произошла по стечению обстоятельств. Важно правильно выплеснуть свой гнев, без ущерба окружению и понять, что злимся на того, кто умер или оставил нас. Когда приходится поверить в правдивость изменений, наступает стадия гнева. Человек испытывает сильные эмоции, злость, стремление найти виновного в том, что происходит. Он направляет свой гнев попеременно то на себя самого, то на окружающих, то на высшие силы. Становится агрессивен, раздражителен. Любое незначительное событие может вызвать у него приступ истерики.</Regular>
                    <Regular sb>Внимание! Некоторое количество гнева является здоровым показателем того, что мы начинаем воспринимать факты такими, какие они есть. «Первым сильным чувством, прорывающим пелену оцепенения и обманчивого равнодушия, нередко оказывается злость» (Ф.Е. Василюк)</Regular>
                    <Regular sb>3-й блок: Торг или желание договориться.</Regular>
                    <Regular>Типичные проявления фазы торга:</Regular>
                    <Regular>— обращение к Богу, одержимое вымаливание благополучного исхода;</Regular>
                    <Regular>— посещение гадалок и экстрасенсов в поиске помощи; розыск знаков судьбы и неистовая вера в приметы и суеверия;</Regular>
                    <Regular>— разочарование в традиционных методах лечения и поиск народных способов;</Regular>
                    <Regular>— сочетание взаимоисключающих действий (посещение церквей и визиты к гадалкам).</Regular>
                    <Regular sb>4-й блок: Выход из депрессии.</Regular>
                    <Regular>Чтобы пройти эту стадию, следует вести обычный образ жизни, больше общаться, заниматься любимым делом. Признак того, что этот этап уже наступил — смена вопросов, которые задаёт испытавший горе человек. Вместо «Почему это случилось со мной?» он спрашивает: «Как выйти из этого кризиса?». В поисках ответа на этот вопрос человек начнёт испытывать новые чувства — более позитивные и жизнеутверждающие.</Regular>
                    <Regular sb>5-й блок: Принятие, как путь к жизни.
                    </Regular>
                    <Regular>В период принятия человек чувствует моральную опустошённость, усталость. Он истощён и готов принять данность происходящего. Он много думает о том, что случилось, но не истязает себя, а делает выводы. Они касаются всех сфер жизни, поскольку любой стресс отражается на них в большей или меньшей степени.</Regular>
                    <Regular>Именно в этот период он может открыть в себе что-то новое — умения и таланты, таившиеся где-то внутри.
                    </Regular>
                    <Regular sb>О стоимости:
                    </Regular>
                    <Regular>— Online 13 встреч — {sorrow?.online}.</Regular>
                    {sorrow?.offline && <Regular>— Offline 13 встреч — {sorrow?.offline}.</Regular>}
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Button onClick={() => window.open('https://t.me/MaryiaMazur', '_self')}>Записаться</Button>
                </div>
            </section>
        </div>
    );
};

export default Sorrow;
