import React from 'react';
import ServiceCard from "./ServiceCard";

const Service = ({image_left, image_right, serviceName_left, serviceName_right, path_left, path_right}) => {
    return (
        <div className="services__row">
            <ServiceCard path={path_left} serviceName={serviceName_left} image={image_left}/>
            <ServiceCard path={path_right} serviceName={serviceName_right} image={image_right}/>
        </div>
    );
};

export default Service;
