import React from "react";
import "../style/pages/contacts.scss";
import Headline1 from "../components/typography/headline1";
import Subtitle from "../components/typography/subtitle";
import Icons from "../components/Icons/Icons";
import Button from "../components/buttons/button";
import { Link } from "react-router-dom";
import { CountryContext } from "contexts/countryContext";

const Contacts = () => {
  const { COUNTRY } = React.useContext(CountryContext);
  return (
    <div className="contacts">
      <section className="contacts-width contacts__map">
        <Headline1>Контакты</Headline1>
        <div className="map__wrapper">
          <iframe
            src="https://yandex.ru/map-widget/v1/?um=constructor%3Ae8769d56bf425944b72136109d470e52b9be8bda6a65e20fb607c4b19556271a&amp;source=constructor"
            width="657"
            height="424"
            frameBorder="0"
          />
          <div>
            <Subtitle>Суворова 127, корпус 5, 3-й этаж, 303 кабинет</Subtitle>
            <Subtitle>+375 29 549-49-52</Subtitle>
            <Subtitle>+375 29 549-49-52</Subtitle>
            <Subtitle style={{ marginBottom: "5%" }}>
              psi_mariamazur@mail.ru
            </Subtitle>
            <Icons />
            <Button
              style={{ marginTop: "5%" }}
              onClick={() => window.open("https://t.me/MaryiaMazur", "_self")}
            >
              Записаться
            </Button>
          </div>
        </div>
      </section>
      <section id="#payment" className="contacts-width contacts__payment">
        <Headline1>Способы оплаты</Headline1>
        {COUNTRY === "Belarus" ? <><Subtitle>
          1.{" "}
          <Link to={"/payment"} onClick={() => window.scrollTo(0, 0)}>
            Сервис «Оплати» (по нажатию произойдет переход на страницу оплаты)
          </Link>
        </Subtitle>
        <Subtitle>
          2. Оплата по реквизитам ИП Мазур Мария Викторовна р/с
          №BY39BLBB30130791214384001001 в ОАО «БЕЛИНВЕСТБАНК» БИК(код банка) —
          BLBBBY2X УНП 791214384
        </Subtitle></> : 
        <Subtitle>
          1. Оплата по реквизитам ИП Мазур Мария Викторовна р/с
          №BY39BLBB30130791214384001001 в ОАО «БЕЛИНВЕСТБАНК» БИК(код банка) —
          BLBBBY2X УНП 791214384
        </Subtitle>}
        
      </section>
    </div>
  );
};

export default Contacts;
