import React from "react";
import "../../style/pages/servicesPages.scss";
import Headline1 from "../../components/typography/headline1";
import ButtonArrow from "../../components/buttons/button-arrow";
import { Link } from "react-router-dom";
import Subtitle from "../../components/typography/subtitle";
import Headline3 from "../../components/typography/headline3";
import individualImg from "../../images/servicePages/individual.png";
import Button from "../../components/buttons/button";
import Regular from "../../components/typography/regular";
import { CountryContext } from "../../contexts/countryContext";

const Individual = () => {
  const { individual } = React.useContext(CountryContext);

  return (
    <div className="individual">
      <section className="service-width service__header">
        <Link onClick={() => window.scrollTo(0, 0)} to="/services">
          <ButtonArrow left transparent>
            Вернуться к услугам
          </ButtonArrow>
        </Link>
        <Headline1>Индивидуальная консультация online/offline</Headline1>
        <div className="service__price">
          <Headline3>Стоимость {individual}.</Headline3>
          <Subtitle>
            Продолжительность: <b>50 минут</b>
          </Subtitle>
        </div>
      </section>
      <section className="service-width service__main">
        <div className="service__main-left">
          <Regular sb>
            Индивидуальная консультация проходит онлайн или офлайн.
          </Regular>
          <Regular>1. Самопознание. Анализ вашей личности.</Regular>
          <Regular>
            2. Понимание людей вокруг вас. Анализ взаимосвязей с другими
            участниками вашей истории (мужа, жены, детей, родственников, коллег
            и т.д.).
          </Regular>
          <Regular>
            3. Анализ вашей проблемной ситуации. С объяснением причин её
            возникновения, чаще всего — с описанием самой модели данной
            ситуации.
          </Regular>
          <Regular>
            4. Знакомство с закономерностями жизни и психологии. Прежде всего, с
            теми закономерностями жизни и работы человеческой психики, что имеют
            отношение конкретно к вашей ситуации.
          </Regular>
          <Regular>
            5. Экспертную оценку проблемной ситуации, относительно законов
            жизни, мышления и поведения человека.
          </Regular>
          <Regular>
            6. Прогнозирование вашего будущего. Оценку перспектив в вашей
            истории, их варианты: позитивные или негативные, в зависимости от
            того, на какой стадии развития вашей проблемной ситуации вы ко мне
            обратились.
          </Regular>
          <Regular>7. Помощь в принятии сложного жизненного решения.</Regular>
          <Regular>
            8. Влияние на ваше будущее. Вы составляете конкретные рекомендации –
            «дорожную карту», что можно сделать в вашем случае, с возможным
            (если потребуется) приведением примеров аналогичных ситуаций из
            практики работы.
          </Regular>
          <Regular>
            9. Мою доброжелательную критику: вас, ваших действий, других
            участников проблемной ситуации и их поведения, если это того
            потребует.
          </Regular>
          <Regular>
            10. Мою психологическую поддержку вас в сложной жизненной ситуации,
            какой бы драматической она ни была.
          </Regular>
        </div>
        <div className="service__main-right">
          <img width={"100%"} src={individualImg} />
          <Button
            onClick={() => window.open("https://t.me/MaryiaMazur", "_self")}
          >
            Записаться
          </Button>
        </div>
      </section>
    </div>
  );
};

export default Individual;
