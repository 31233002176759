import React from 'react';
import services from "../../utils/services";
import PaymentService from "./PaymentService";

const PaymentList = ({dispatch, setSelectedService, selectedService}) => {
    const [parsedServices, setParsedServices] = React.useState(Object.keys(services).map((service, index) => {
        return [services[service].name, service];
    }));
    const [selected, setSelected] = React.useState(null);
    
    return parsedServices.map((service, index) => {
        return (
            <PaymentService
                selectedService={selectedService}
                setSelectedService={setSelectedService}
                setSelected={setSelected}
                selected={selected}
                dispatch={dispatch}
                name={service[0]}
                service={service[1]}
                key={index}
            />
        )
    });
};

export default PaymentList;
