import { Prices } from "utils/prices";

const services = {
  errorStatuses: [2, 3, 4, 5],
  orderNumberToName: {
    1: "Индивидуальная консультация online/offline",
    2: "Индивидуальная консультация с проживанием деструктивных эмоций online/offline",
    3: "Поддерживающая консультация online/offline",
    4: "Семейные консультации online/offline",
    5: "Радикальное прощение 21 день. Курс «Pro» + 2 консультации",
    6: "Радикальное прощение 21 день. Курс «VIP» - индивидуальное сопровождение + 2 бесплатные консультации",
    7: "Онлайн-программа «Вес»",
    8: "Онлайн-программа «Радикальные деньги». Пакет Pro - продолжительность 90 дней",
    9: "Онлайн-программа «Радикальные деньги». Пакет Vip - продолжительность 90 дней, полное индивидуальное сопровождение",
    10: "Радикальное принятие себя. Курс «Pro» + 2 консультации",
    11: "Радикальное принятие себя. Курс «Vip» — индивидуальное сопровождение + 2 бесплатные консультации",
    12: "«Сатори» (Осознание). Индивидуальное сопровождение в игре",
    13: "«Сатори» (Осознание). В группе ( 3-5 человек)",
    14: "«Скорбь». Online 13 встреч",
    15: "«Скорбь». Offline 13 встреч",
  },
  individual: {
    name: "Индивидуальная консультация online/offline",
    type: 0,
    orderNumber: 1,
    sum: Prices.Belarus.individual,
  },
  destructive: {
    name: "Индивидуальная консультация с проживанием деструктивных эмоций online/offline",
    type: 0,
    orderNumber: 2,
    sum: Prices.Belarus.destructive,
  },
  support: {
    name: "Поддерживающая консультация online/offline",
    type: 0,
    orderNumber: 3,
    sum: Prices.Belarus.support,
  },
  family: {
    name: "Семейные консультации online/offline",
    type: 0,
    orderNumber: 4,
    sum: Prices.Belarus.family,
  },
  forgiveness: {
    name: "Радикальное прощение 21 день.",
    type: 1,
    pro: {
      name: "Курс «Pro» + 2 консультации",
      orderNumber: 5,
      sum: Prices.Belarus.forgiveness.pro,
    },
    vip: {
      name: "Курс «VIP» - индивидуальное сопровождение + 2 бесплатные консультации",
      orderNumber: 6,
      sum: Prices.Belarus.forgiveness.vip,
    },
  },
  weight: {
    name: "Онлайн-программа «Вес»",
    type: 0,
    orderNumber: 7,
    sum: Prices.Belarus.weight,
  },
  money: {
    name: "Онлайн-программа «Радикальные деньги»",
    type: 1,
    pro: {
      name: "Пакет Pro - продолжительность 90 дней",
      orderNumber: 8,
      sum: Prices.Belarus.money.pro,
    },
    vip: {
      name: "Пакет Vip - продолжительность 90 дней, полное индивидуальное сопровождение",
      orderNumber: 9,
      sum: Prices.Belarus.money.vip,
    },
  },
  yourself: {
    name: "Радикальное принятие себя",
    type: 1,
    pro: {
      name: "Курс «Pro» + 2 консультации",
      orderNumber: 10,
      sum: Prices.Belarus.yourself.pro,
    },
    vip: {
      name: "Курс «Vip» — индивидуальное сопровождение + 2 бесплатные консультации",
      orderNumber: 11,
      sum: Prices.Belarus.yourself.vip,
    },
  },
  satori: {
    name: "«Сатори» (Осознание)",
    type: 1,
    individual: {
      name: "Индивидуальное сопровождение в игре",
      orderNumber: 12,
      sum: Prices.Belarus.satori.individual,
    },
    group: {
      name: "В группе ( 3-5 человек)",
      orderNumber: 13,
      sum: Prices.Belarus.satori.group,
    },
  },
  sorrow: {
    name: "«Скорбь»",
    type: 1,
    online: {
      name: "Online 13 встреч",
      orderNumber: 14,
      sum: Prices.Belarus.sorrow.online,
    },
    offline: {
      name: "Offline 13 встреч",
      orderNumber: 15,
      sum: Prices.Belarus.sorrow.offline,
    },
  },
};

export default services;
