import React from 'react';
import {Link} from "react-router-dom";
import ButtonArrow from "../../../components/buttons/button-arrow";
import Regular from "../../../components/typography/regular";
import private_ from "../../../images/servicePages/private_.png";
import Button from "../../../components/buttons/button";
import Headline2 from "../../../components/typography/headline2";

const Private = () => {
    return (
        <div className="support">
                <Link onClick={() => window.scrollTo(0, 0)} to="/services"><ButtonArrow left transparent>Вернуться к услугам</ButtonArrow></Link>
            <section className="mobile-width service__header">
                <Headline2>Личный прием психолога</Headline2>
            </section>
                    <img width={"100%"} src={private_} />
            <section className="mobile-width">
                    <Regular>Любая, даже самая сложная на ваш взгляд проблемная ситуация, имеет решение. </Regular>
                    <Regular>Моя задача — помочь вам её решить и предотвратить повторения в будущем.</Regular>
                    <Regular>Психолог поможет вам принять решение самому и не станет принимать решения за Вас. У психолога вы не пациент (больной), а клиент.</Regular>
                    <Regular>Решение проблем произойдёт с помощью осознавания человеком себя, своих чувств, потребностей, желаний, своей мыслительной деятельности и других процессов, связанных с жизнедеятельностью.</Regular>
                    <Regular>Это приведёт к тому, что парадоксальным образом может измениться проблемная ситуация в жизни, клиенту должно стать легче.</Regular>
                    <Regular>На сеансе нет запретов, а если и есть, то разве что ваши собственные. Беседа может касаться поступков и чувств, переживаний и фантазий, сновидений и воспоминаний. Можно говорить о детстве и взаимоотношениях с родителями, о подростковом периоде, о взаимоотношениях дома и на работе, о довольстве и недовольстве, агрессивных фантазиях и страхах… словом, обо всем.</Regular>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Button onClick={() => window.open('https://t.me/MaryiaMazur', '_self')}>Записаться</Button>
                </div>
            </section>
        </div>
    );
};

export default Private;
