import React from "react";
import styled from "styled-components";
import { colors } from "../../style/_variables";

const IntroductionMobile = ({ image, children }) => {
  return (
    <IntroductionStyled image={image} className={"introduction-mobile"}>
      {children}
    </IntroductionStyled>
  );
};

const IntroductionStyled = styled.div`
  color: ${colors.white};
  background: url(${({ image }) => image}) no-repeat center top,
    linear-gradient(transparent, rgba(0, 0, 0, 1));
  background-blend-mode: overlay;
  background-size: cover;
  padding: 40vh 2.5% 40px 2.5%;
`;

export default IntroductionMobile;
