import React from 'react';
import {Link} from "react-router-dom";
import ButtonArrow from "../../../components/buttons/button-arrow";
import Headline3 from "../../../components/typography/headline3";
import Subtitle from "../../../components/typography/subtitle";
import Regular from "../../../components/typography/regular";
import destructiveImg from "../../../images/servicePages/destructive.png";
import Button from "../../../components/buttons/button";
import {CountryContext} from "../../../contexts/countryContext";
import Headline2 from "../../../components/typography/headline2";

const Destructive = () => {
    const { destructive } = React.useContext(CountryContext);


    return (
        <div className="destructive">
                <Link onClick={() => window.scrollTo(0, 0)} to="/services"><ButtonArrow left transparent>Вернуться к услугам</ButtonArrow></Link>
            <section className="mobile-width service__header">
                <Headline2>Индивидуальная консультация с проживанием деструктивных эмоций online/offline</Headline2>
                <Headline3>Стоимость {destructive}.</Headline3>
                <Subtitle>Продолжительность: <b>1 час 30 минут</b></Subtitle>
            </section>
            <img width={"100%"} src={destructiveImg} />
                <div className="mobile-width">
                    <Regular>Индивидуальная консультация с проживанием деструктивных эмоций, с применением методов крикотерапии, двигательной терапии, дыхательных практик и научением техникам самопомощи.
                    </Regular>
                    <Regular>— Тревожные и панические расстройства, страхи и фобии;</Regular>
                    <Regular>— Пережитые катастрофы, насилие, нападения и прочие травмирующие события, ПТСР;</Regular>
                    <Regular>— Горе и утрата;</Regular>
                    <Regular>— Сексуальные дисфункции;</Regular>
                    <Regular>— Зависимости;</Regular>
                    <Regular>— Депрессия;</Regular>
                    <Regular>— Самоповреждающее поведение;</Regular>
                    <Regular>— Расстройства пищевого поведения;</Regular>
                    <Regular>— Соматические нарушения (психосоматика).</Regular>
                </div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Button onClick={() => window.open('https://t.me/MaryiaMazur', '_self')}>Записаться</Button>
            </div>
        </div>
    );
};

export default Destructive;
