import React from 'react';
import Introduction from "../components/Introduction/Introduction";
import mariya_3 from "../images/mariya/mariya_3.png";
import mariya_4 from "../images/mariya/mariya_4.png";
import Headline1 from "../components/typography/headline1";
import Subtitle from "../components/typography/subtitle";
import Button from "../components/buttons/button";
import "../style/pages/about.scss";
import Certificates from "../components/Certificates";
import Icons from "../components/Icons/Icons";
import {colors} from "../style/_variables";
import Headline2 from "../components/typography/headline2";

const About = () => {
    return (
        <div className="about">
            <section className="about__introduction">
                <Introduction image={mariya_3} center>
                    <div>
                        <Headline1>Мария Мазур</Headline1>
                        <Subtitle>Я профессиональный психолог, за плечами имею большой опыт консультирования <br /> Приветствую Вас на моём сайте!</Subtitle>
                        <Subtitle>– Провожу личные и онлайн консультации</Subtitle>
                        <Subtitle>– Помогаю лучше понять себя и происходящее в жизни</Subtitle>
                        <Subtitle>– Работаю с телом, умом и эмоциями</Subtitle>
                        <Subtitle>– Ведущая игры «Сатори»</Subtitle>
                        <Subtitle>– В профессии с 2015 года</Subtitle>
                        <Button onClick={() => window.open('https://t.me/MaryiaMazur', '_self')}>Записаться</Button>
                    </div>
                </Introduction>
            </section>
            <section className="about__motivation" >
                <Introduction image={mariya_4} right>
                    <div>
                        <Headline1>Что меня привело в психологию?</Headline1>
                        <Subtitle>Скорее всего, это мой бунтарский Дух и неутолимое желание жить. Могу смело себя назвать неутомимым исследователем и экспериментатором. Эти качества появились у меня уже с раннего детства.</Subtitle>
                        <Subtitle>Свою жизнь я связала с психологией, когда мне был 21 год.
                            Я работала менеджером по продажам, и в скором времени уже руководила большим отделом – 15 человек. Большинство сотрудников я учила с нуля и многих вывела на топовый уровень.
                            </Subtitle>
                        <Subtitle>Этим заслужила назначение руководителем филиала компании в г. Гродно. Именно руководящая должность подтолкнула меня в этот новый и интересный мир ‒ в психологию.</Subtitle>
                        <Subtitle>Участвуя в личностном росте подчинённых, я наполнялась знаниями о психологии человека.</Subtitle>
                        <Subtitle>А далее было много интересных тренингов и курсов в этом направлении, получение высшего образования по специальности практическая психология.</Subtitle>
                        <Subtitle>И сегодня я продолжаю постоянно повышать свою квалификацию.
                            Я вегетарианец, открывший для себя интуитивное питание.
                            Открываю познание жизни через духовность, люблю путешествовать и веду активный образ жизни.</Subtitle>
                    </div>
                </Introduction>
            </section>
            <section className="about-width about__timeline">
                <Headline1>Образование</Headline1>
                <div id="timeline">
                    <div className="timeline-item">
                        <div className="timeline-icon">
                        </div>
                        <div className="timeline-content">
                            <Subtitle sb>2015‒2020</Subtitle>
                            <Subtitle>Гродненский государственный университет имени Янки Купалы
                                по специальности практическая психология</Subtitle>
                        </div>
                    </div>
                    <div className="timeline-item">
                        <div className="timeline-icon"></div>
                        <div className="timeline-content right">
                            <Subtitle sb>2018</Subtitle>
                            <Subtitle className="subtitle text-start">Диплом практика по метафорической и арт-терапии.
                                Общее количество часов базовой учебной подготовки по метафорической психотерапии — 140
                                часов.
                                Количество часов интервизии по метафорической психотерапии — 60 часов.
                                Количество часов супервизии по метафорической психотерапии — 36 часов.
                                Общее количество часов базовой учебной подготовки по арт-терапии — 100 часов.
                                Количество часов интервизии по арт-терапии — 36 часов.
                                Количество часов супервизии по арт-терапии — 18 часов.</Subtitle>
                        </div>
                    </div>
                    <div className="timeline-item">
                        <div className="timeline-icon">
                        </div>
                        <div className="timeline-content">
                            <Subtitle sb>2019</Subtitle>
                            <Subtitle>Сертификат-грамота: Тренер «Радикального Прощения» по
                                методу Колина Типпинга.
                                Сертификат-грамота: ведение трансформационной Игры Радикального Прощения «Сатори» по
                                методу Колина Типпинга.
                                с 1 по 21 марта прошла обучение в Италии по программе «Профессиональный профиль
                                арт-терапевта и его функции». Под руководством Марии Борисовны Черновой. (филолог,
                                социолог, пед. психолог), а также представителя династии художников-постмодернистов
                                Андреа Сакетти.
                            </Subtitle>
                        </div>
                    </div>
                    <div className="timeline-item">
                        <div className="timeline-icon"></div>
                        <div className="timeline-content right">
                            <Subtitle sb>2021</Subtitle>
                            <Subtitle>Прошла обучение на семинаре-практикуме «Применение
                                десенсибилизации при помощи движений глаз в работе практического психолога (ДПДГ) – 100
                                академических часов.
                                Прошла восемь модулей учебного курса: „Эриксоновская терапия“ – 24 академических часа.
                                Тренер Павел Лебедько.
                                Прошла практический курс по профориентации старшеклассников.</Subtitle>
                        </div>
                    </div>
                    <div className="timeline-item">
                        <div className="timeline-icon">
                        </div>
                        <div className="timeline-content">
                            <Subtitle sb>2021-2023</Subtitle>
                            <Subtitle>ВЕИМ (магистратура) восточно-европейский институт
                                психоанализа. Срок обучения 2,5 года. Профессия психолог-психоаналитик.
                                Тема «Инфантильная сексуальность нарциссической личности:эрогенный мазохизм».</Subtitle>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about__certificates">
                <Headline1 className="about-width">Сертификаты и дипломы</Headline1>
                <Certificates />
            </section>
            <section className="about__prefooter">
                <div className="prefooter__wrapper about-width">
                    <Headline2 color={colors.white}>Записывайтесь на пробную терапию и я смогу подобрать наиболее эффективные методики и формат</Headline2>
                    <Button onClick={() => window.open('https://t.me/MaryiaMazur', '_self')}>Записаться</Button>
                    <Icons white />
                </div>
            </section>
        </div>
    );
};

export default About;
