import styled from 'styled-components';
import {colors} from "../../style/_variables";

const Button = styled.button.attrs((props) => ({
    className: `subtitle subtitle-sb`,
}))`
  display: flex;
  align-items: center;
  color: ${({transparent, plain}) => transparent ? plain ? colors.white : colors.blue : colors.white};
  padding: ${({transparent}) => transparent ? '0px 20px' : '16px 60px'};
  border-radius: 4px;
  margin: 20px 0px;
  cursor: pointer;
  background: ${({transparent}) => transparent ? 'transparent' : colors.blue};
  border: ${({transparent}) => transparent ? 'none' : colors.blue};
  box-shadow: ${({transparent}) => transparent ? 'initial' : '0px 0px 4px #5068A7'};
  
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
    border: none;
    background: transparent;
    color: #15151F;
    box-shadow: none;
  }
`
export default Button;
