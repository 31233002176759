import React from "react";
import { Link } from "react-router-dom";
import ButtonArrow from "../../components/buttons/button-arrow";
import Headline1 from "../../components/typography/headline1";
import Regular from "../../components/typography/regular";
import Headline3 from "../../components/typography/headline3";
import Button from "../../components/buttons/button";
import { CountryContext } from "../../contexts/countryContext";

const Yourself = () => {
  const { yourself, COUNTRY } = React.useContext(CountryContext);
  return (
    <div className="yourself">
      <section className="service-width service__header">
        <Link onClick={() => window.scrollTo(0, 0)} to="/services">
          <ButtonArrow left transparent>
            Вернуться к услугам
          </ButtonArrow>
        </Link>
        <Headline1>Радикальное принятие себя</Headline1>
        <Regular>
          Цель программы – проработка чувств вины и стыда, изменение привычных
          форм поведения, а также научиться проживать эти чувства, чтобы не
          испытывать страха перед будущем. Программой предусмотрены занятия с
          корневыми убеждениями и повторяющиеся ситуациями в вашей жизни.
        </Regular>
        <Regular>
          Участие в программе поможет понять свою уникальность и
          востребованность, а также пробудить интерес окружающих. Разбор причины
          происходящего.
        </Regular>
        <Regular sb>Для кого подходит эта программа?</Regular>
        <Regular>
          Онлайн - программа "Прощение и принятие себя" подойдет людям, которых
          хотят избавиться от чувства стыда и вины за свои ошибки. Люди, которые
          хотят поднять свою самооценку и принять себя такими какие они есть.
        </Regular>
        <Regular>
          <b>Продолжительность курса</b> Радикальное Принятие себя ‒ от 13 до 21
          дней.
        </Regular>
      </section>
      <section className="service-width service__main">
        <div className="service__main-left">
          <Headline3>
            Курс «Pro» + 2 консультации / Стоимость {yourself?.pro}
          </Headline3>
          <Regular>
            <Regular sb>В курс входит:</Regular>
            <Regular>• Работа в закрытой онлайн группе, ВК</Regular>
            <Regular>
              • Две бесплатные, индивидуальные консультации по 50 минут
            </Regular>
            {COUNTRY === "Belarus" && <Regular>
            • Возможность брать поддерживающие консультации по сниженной цене.
          </Regular>}

            <Regular>
              • Сопровождение, обратная связь на протяжении 21 дня со старта
              программы.
            </Regular>
            <Regular>• Диагностика рисунков, мандала.</Regular>
            <Regular>• Релаксация с помощью погружения в медитацию.</Regular>
            <Regular>• 4 модуля, состоящие из 13 уроков.</Regular>

            <Regular sb>Модуль 1. Подготовка</Regular>
            <Regular>
              В первом модуле вы узнаёте основные инструкции и рекомендации о
              том, как проходить программу, чтобы получить максимальную пользу.
            </Regular>

            <Regular sb>Модуль 2. Прощение себя</Regular>
            <Regular>
              Во втором модуле вы высказываете свои претензии к себе,
              вспоминаете наиболее яркие ситуации, за которые вам стыдно. Вы
              погружаетесь в чувство вины, переосмысляете прошлое и прощаете
              себя.
            </Regular>

            <Regular sb>Модуль 3. Принятие себя</Regular>
            <Regular>
              В третьем модуле вы измените своё собственное мнение о себе в
              лучшую сторону, поднимите свою самооценку, поверите в то, что вы
              достойны всего самого наилучшего.
            </Regular>

            <Regular sb>Модуль 4. Освобождение от секретов</Regular>
            <Regular>
              В четвёртом модуле вы освобождаетесь от своих и чужих постыдных
              секретов и тайн, которые вы носили с собой многие годы. Вина и
              стыд уходят. Вас наполняет огромный поток энергии и любви.
            </Regular>
            <Regular>Вы исцелены.</Regular>
            <Regular>• Заключение и подведение итогов</Regular>
            <Regular>• Анализ проделанной работы, экспертная оценка</Regular>
            <Regular>• В подарок — 13 шагов Радикального прощения себя</Regular>

            <Regular>
              Плюсом к программе я подготовила для вас подарок — 13 шагов
              Радикального прощения себя. Это процесс Радикального прощения,
              который занимает всего 10 минут. Вы просто слушаете аудиозапись и
              отвечаете на вопросы словом «Да». Это очень просто, но невероятно
              эффективно. Прощения себя — это процесс расслабления, который
              окунает вас в незабываемые ощущения любви и гармонии с собой и
              окружающим миром. Просто слушайте запись и получайте удовольствие.
              13 шагов Радикального прощения себя — это возможность очистить и
              исцелить себя, чтобы открыть своё сердце для благодарности и
              любви. Простив себя, вы позволите себе получать счастье, любовь и
              достаток.
            </Regular>
          </Regular>
          {COUNTRY === "Belarus" && <><Headline3>Услуга «Раздели оплату» (200 бел. руб.)</Headline3>
          <Regular>
            Данная услуга предоставляет возможность оплатить онлайн-программу в
            2 платежа. Первый взнос необходимо внести на момент старта
            программы, а вторая часть после окончания курса.
          </Regular></>}
          <Button
            onClick={() => window.open("https://t.me/MaryiaMazur", "_self")}
          >
            Записаться
          </Button>
        </div>
        <div className="service__main-right-no-image">
          <Headline3>
            Курс «Vip» — индивидуальное сопровождение + 2 бесплатные
            консультации Стоимость {yourself?.vip}.
          </Headline3>
          <Regular sb>В курс входит:</Regular>
          <Regular>
            • Работа в закрытой онлайн группе (в любом из мессенджеров Viber,
            WhatsApp, Telegram)
          </Regular>
          <Regular>• Индивидуальное сопровождение.</Regular>
          <Regular>
            • Две бесплатные, индивидуальные консультации по 50 минут
          </Regular>
          {COUNTRY === "Belarus" && <Regular>
            • Возможность брать поддерживающие консультации по сниженной цене.
          </Regular>}
          <Regular>
            • Сопровождение, обратная связь на протяжении 21 дня со старта
            программы.
          </Regular>
          <Regular>• Диагностика рисунков, мандала.</Regular>
          <Regular>• Релаксация с помощью погружения в медитацию.</Regular>
          <Regular>• 4 модуля, состоящие из 13 уроков.</Regular>

          <Regular sb>Модуль 1. Подготовка</Regular>
          <Regular>
            В первом модуле вы узнаёте основные инструкции и рекомендации о том,
            как проходить программу, чтобы получить максимальную пользу.
          </Regular>

          <Regular sb>Модуль 2. Прощение себя</Regular>
          <Regular>
            Во втором модуле вы высказываете свои претензии к себе, вспоминаете
            наиболее яркие ситуации, за которые вам стыдно. Вы погружаетесь в
            чувство вины, переосмысляете прошлое и прощаете себя.
          </Regular>

          <Regular sb>Модуль 3. Принятие себя</Regular>
          <Regular>
            В третьем модуле вы измените своё собственное мнение о себе в лучшую
            сторону, поднимите свою самооценку, поверите в то, что вы достойны
            всего самого наилучшего.
          </Regular>

          <Regular sb>Модуль 4. Освобождение от секретов</Regular>
          <Regular>
            В четвёртом модуле вы освобождаетесь от своих и чужих постыдных
            секретов и тайн, которые вы носили с собой многие годы. Вина и стыд
            уходят. Вас наполняет огромный поток энергии и любви.
          </Regular>
          <Regular>Вы исцелены.</Regular>
          <Regular>• Заключение и подведение итогов</Regular>
          <Regular>• Анализ проделанной работы, экспертная оценка</Regular>
          <Regular>• В подарок — 13 шагов Радикального прощения себя</Regular>

          <Regular>
            Плюсом к программе я подготовила для вас подарок — 13 шагов
            Радикального прощения себя. Это процесс Радикального прощения,
            который занимает всего 10 минут. Вы просто слушаете аудиозапись и
            отвечаете на вопросы словом «Да». Это очень просто, но невероятно
            эффективно. Прощения себя — это процесс расслабления, который
            окунает вас в незабываемые ощущения любви и гармонии с собой и
            окружающим миром. Просто слушайте запись и получайте удовольствие.
            13 шагов Радикального прощения себя — это возможность очистить и
            исцелить себя, чтобы открыть своё сердце для благодарности и любви.
            Простив себя, вы позволите себе получать счастье, любовь и достаток.
          </Regular>
          <Button
            onClick={() => window.open("https://t.me/MaryiaMazur", "_self")}
          >
            Записаться
          </Button>
        </div>
      </section>
    </div>
  );
};

export default Yourself;
