import React from "react";
import { QRCodeSVG } from "qrcode.react";
import Headline2 from "../../components/typography/headline2";
import "../../style/pages/payment.scss";
import Headline3 from "../../components/typography/headline3";
import { colors } from "../../style/_variables";
import PaymentList from "./PaymentList";
import Button from "../../components/buttons/button";
import PaymentFinal from "./PaymentFinal";

const initialState = {
    first: true,
    second: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case "OPEN_SECOND":
            return {
                ...state,
                first: false,
                second: true,
            };
        case "OPEN_FIRST":
            return {
                ...state,
                first: true,
                second: false,
            };
        default:
            return state;
    }
};

const Payment = ({mobile}) => {
    const [selectedService, setSelectedService] = React.useState(null);
    const [serviceForPayment, setServiceForPayment] = React.useState(null);
    const [disabled, setDisabled] = React.useState(true);
    const [state, dispatch] = React.useReducer(reducer, initialState);
    return (
        <div className={`${mobile ? 'mobile-width' : ''} payment-width`}>
            <Headline2>Оплата через сервис "Оплати"</Headline2>
            <Headline3
                clickable={true}
                onClick={() => {
                    dispatch({ type: "OPEN_FIRST" });
                    setDisabled(true);
                }}
                color={state.first ? colors.blue : colors.gray}
            >
                1. Выберите желаемую услугу из списка ниже:{" "}
            </Headline3>
            {state.first && (
                <PaymentList
                    selectedService={selectedService}
                    setSelectedService={setSelectedService}
                    dispatch={dispatch}
                />
            )}
            {selectedService?.orderNumber && (
                <Button
                    style={{ background: "transparent", color: colors.blue }}
                    onClick={() => {
                        setServiceForPayment(selectedService);
                        dispatch({ type: "OPEN_SECOND" });
                        setSelectedService(null);
                        setDisabled(false);
                    }}
                >
                    Перейти к оплате
                </Button>
            )}
            <Headline3
                disabled={disabled}
                onClick={() => !disabled && dispatch({ type: "OPEN_SECOND" })}
                color={disabled ? colors.gray : colors.blue}
            >
                2. Отсканируйте QR-код для оплаты услуги:{" "}
            </Headline3>
            {state.second && <PaymentFinal mobile={mobile} dispatch={dispatch} serviceForPayment={serviceForPayment} />}
        </div>
    );
};

export default Payment;
