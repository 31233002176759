import React from 'react';

const Burger = ({setIsOpen, isOpen}) => {
    return (
        <>
            <div className={`burger-menu ${isOpen}`} onClick={() => {
                setIsOpen(prev => !prev)
            }}>
                <span className={`burger-line ${isOpen}`} />
            </div>
        </>
    );
};

export default Burger;
