import React from 'react';
import {Link} from "react-router-dom";
import ButtonArrow from "../../components/buttons/button-arrow";
import Headline1 from "../../components/typography/headline1";
import Regular from "../../components/typography/regular";
import online_consultations from "../../images/servicePages/online_consultations.png";
import Button from "../../components/buttons/button";

const Coaching = () => {
    return (
        <div className="support">
            <section className="service-width service__header">
                <Link onClick={() => window.scrollTo(0, 0)} to="/services"><ButtonArrow left transparent>Вернуться к услугам</ButtonArrow></Link>
                <Headline1>Личный коучинг</Headline1>
            </section>
            <section className="service-width service__main">
                <div className="service__main-left">
                    <Regular>Разновидность коучинга, занимающегося в большей степени не бизнесом, а построением жизни клиента. </Regular>
                    <Regular>Метод обучения, в процессе которого человек, называющийся «коуч», помогает обучающемуся достичь некой жизненной или профессиональной цели. В отличие от менторства, коучинг сфокусирован на достижении чётко определённых целей вместо общего развития.</Regular>
                    <Regular sb>Виды коучинга:</Regular>
                    <div className="service__hrefs">
                        <Link onClick={() => window.scrollTo(0, 0)} to="/services/forgiveness"><ButtonArrow transparent>Радикальное Прощение 21 день</ButtonArrow></Link>
                        <Link onClick={() => window.scrollTo(0, 0)} to="/services/yourself"><ButtonArrow transparent>Радикальное Принятие Себя</ButtonArrow></Link>
                        <Link onClick={() => window.scrollTo(0, 0)} to="/services/money"><ButtonArrow transparent>Программа «Радикальные деньги»</ButtonArrow></Link>
                        <Link onClick={() => window.scrollTo(0, 0)} to="/services/weight"><ButtonArrow transparent>Программа «Вес»</ButtonArrow></Link>
                        <Link onClick={() => window.scrollTo(0, 0)} to="/services/sorrow"><ButtonArrow transparent>Программа «Скорбь»</ButtonArrow></Link>
                        <Link onClick={() => window.scrollTo(0, 0)} to="/services/satori"><ButtonArrow transparent>Т-игра «Сатори»</ButtonArrow></Link>
                    </div>
                </div>
                <div className="service__main-right">
                    <img width={"100%"} src={online_consultations} />
                    <Button onClick={() => window.open('https://t.me/MaryiaMazur', '_self')}>Записаться</Button>
                </div>
            </section>
        </div>
    );
};

export default Coaching;
