import React from 'react';
import styled from 'styled-components'
import Subtitle from "../typography/subtitle";
import Regular from "../typography/regular";
import { colors } from "../../style/_variables";

const FeedbackCard = ({ name, children, image, mobile }) => {
    return (
        <FeedbackCardStyled image={image}>
            <Subtitle color={image ? colors.white : colors.black} sb>{ name }</Subtitle>
            <Regular color={image ? colors.white : colors.black}>{ children }</Regular>
        </FeedbackCardStyled>

    );
};

const FeedbackCardStyled = styled.div`
  background: ${({image}) => image ? 'url('+ image + ') center no-repeat' : colors.lightblue};
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  z-index: 20;
  height: ${({mobile}) => mobile ? '350px' : '100%'};
  margin-top: ${({mobile}) => mobile ? '5%' : '1%'};
  padding: 20px;
  border-radius: 4px;
`

export default FeedbackCard;
